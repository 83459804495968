(function() {
	'use strict';

	angular.
		module('edistradadhluiApp')
		.controller('ShellController', ShellController);

	ShellController.$inject = [ '$rootScope', '$scope', '$state', 'Auth',
			'Principal', 'ClientsService' ];

	function ShellController($rootScope, $scope, $state, Auth, Principal, ClientsService) {
		var vm = this;
		var validNavigation = false;

		// Declare function
		vm.toggleSidebar = toggleSidebar;
		vm.showSidebar = showSidebar;
		vm.hideSidebar = hideSidebar;
		vm.logout = logout;
		vm.identity = Principal.identity();
		vm.setupTabCloseHandling = setupTabCloseHandling;
		vm.setVersion = setVersion;

        function setVersion() {
            var version_text = $('#version_text');
            var version = "256";
            console.log("setVersion2");
                ClientsService.getVersion().then(function(response) {
                    version = $rootScope.VERSION;
                    version += " | ";
                    version += response.data;
                    version_text.text(version);
                })
                .catch(function(response) {
                    version ="error";
                    version_text.text(version);
                });
        }

		function showSidebar() {
			var toggle = $('.navbar-toggle');
			setTimeout(function() {
				toggle.addClass('toggled');
			}, 430);

			var div = '<div id="bodyClick"></div>';
			$(div).appendTo("body").click(function() {
				hideSidebar();
				var $body = angular.element(document.body);
				var $rootScope = $body.injector().get('$rootScope');
				$rootScope.$apply(function() {
					$rootScope.sidebar = false;
				})
			});

			$('html').addClass('nav-open');
		}

		function hideSidebar() {
			var toggle = $('.navbar-toggle');
			$('html').removeClass('nav-open');
			$('#bodyClick').remove();
			setTimeout(function() {
				toggle.removeClass('toggled');
			}, 400);
		}

		function toggleSidebar() {
			var navbar = $('.navbar-collapse');
			if ($rootScope.sidebar == undefined) {
				$rootScope.sidebar = true;
				navbar.css('min-height', window.screen.height);
			} else {
				$rootScope.sidebar = !$rootScope.sidebar;
			}

			if ($rootScope.sidebar == false) {
				hideSidebar();
			} else {
				showSidebar();
			}
		}

		function logout() {
			// Logout through HTTP request
			Auth.logout();

			// Go to login view
			$state.go('login');
		}

		function setupTabCloseHandling() {
		    validNavigation = false;
		    // Unload is called when closing or refreshing tab
            $(window).unload(function() {
                if (validNavigation == false)
                {
                    logout();
                }
                validNavigation = false;
			});
			// F5 key
			$(document).keydown(function(e) {
				var key = e.which || e.keyCode;
				if (key == 116) validNavigation = true;
			});
			// Attach the event click for all links in the page
			$("a").bind("click", function() {
				validNavigation = true;
			});
			// Attach the event submit for all forms in the page
			$("form").bind("submit", function()
			{
				validNavigation = true;
			});
			// Attach the event click for all inputs in the page
			$("input[type=submit]").bind("click", function()
			{
				validNavigation = true;
			});
		}
	}
})();

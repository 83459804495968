(function() {    
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ReceivablesService', ReceivablesService);

    ReceivablesService.$inject = ['API_URL', '$http', 'UtilService', 'GenerateURL'];

    function ReceivablesService(API_URL, $http, UtilService, GenerateURL) {
        var service = {
            list: list,
            getXlsxURL: getXlsxURL,
            getBlockedPayers: getBlockedPayers,
            getDebtContacts: getDebtContacts,
            getReceivablesStatusDate: getReceivablesStatusDate,
            logReceivablesViewed: logReceivablesViewed
        };

        return service;

        function list(filter) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlReceivables/getLimited',
                data: UtilService.starsToPercents(filter)
            });
        }

        // function getXlsx(filter) {
        //     return $http({
        //         method: 'POST',
        //         url: API_URL + 'api/dhlReceivables/getAsExcelByCustomCriteria',
        //         data: UtilService.starsToPercents(filter),
        //         responseType: 'arraybuffer'
        //     });
        // }

        function logReceivablesViewed() {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlReceivables/logViewed'
            });
        }

        function getXlsxURL(filter) {
            var params = UtilService.starsToPercents(filter);
            var baseURL = API_URL + 'api/dhlReceivables/getAsExcelByCustomCriteria';
            return GenerateURL.generate(baseURL, params);
        }

        function getBlockedPayers(userId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/blocked',
                params: { userId: userId }
            });
        }

        function getDebtContacts(userId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/debtCollectors',
                params: { userId: userId }
            });
        }

        function getReceivablesStatusDate() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlReceivables/getReceivableLastDate'
            });
        }
    }
})();

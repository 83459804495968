(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ClientsController', ClientsController);

    ClientsController.$inject = ['$scope', '$state', '$stateParams', 'ClientsService', 'UtilService', 'Upload', 'API_URL', '$uibModal', 'AlertService'];

    function ClientsController($scope, $state, $stateParams, ClientsService, UtilService, Upload, API_URL, $uibModal, AlertService) {
        var vm = this;

        /*Declare variables*/
        vm.filter = {
            data: {
                size: 10,
                page: 0,
                email: "",
                clientNumber: "",
                clientName: ""
            },
            clear: function() {
                vm.filter.data.email = "";
                vm.filter.data.clientNumber = "";
                vm.filter.data.clientName = "";
            },
            save: function() {
                if (vm.data && vm.data.page)
                    vm.filter.data.page = vm.data.page.number;
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };
        vm.data = null;
        vm.actions = [];
        
        //Controller init
        vm.init = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data))
                vm.client.current();
        };

        //Navigating
        vm.nav = {
            client: function(client) {
                $state.go('client', client);
            }
        };

        //Getting clients
        vm.client = {
            //List clients
            current: function() {
                vm.filter.data.page = 0;

                ClientsService.listWithParams(vm.filter.data)
                    .then(vm.client.then);
            },
            //Go to the first page
            first: function() {
                UtilService.get(vm.data, 'first', null, null)
                    .then(vm.client.then);
            },
            //Go to the previous page
            previous: function() {
                UtilService.get(vm.data, 'prev', null, null)
                    .then(vm.client.then);
            },
            //Go to the next page
            next: function() {
                UtilService.get(vm.data, 'next', null, null)
                    .then(vm.client.then);
            },
            //Go to the last page
            last: function() {
                UtilService.get(vm.data, 'last', null, null)
                    .then(vm.client.then);
            },
            then: function(response) {
                //Set data to controller data
                vm.data = response.data;
                if (response.data._embedded == null) {
                    AlertService.error("clients.clients.clientNotFound");
                } else {
                    AlertService.clear();
                }
                //Write state to URL
                vm.filter.save();
            },
            new: function() {
                var newClientModal = $uibModal.open({
                    templateUrl: 'app/views/clients/newClient/newClient.html',
                    controller: 'NewClientController',
                    controllerAs: 'vm',
                    size: 'lg'
                });

                newClientModal.result.then(function(newClient) {
                    ClientsService.create(newClient).then(function(response) {
                        vm.client.current();
                        AlertService.success("clients.clients.clientCreated");
                    }).catch(function(response) {
                        AlertService.error("clients.clients.errorAdding");
                    });
                });
            }
        };

        vm.management = {
            file: null,
            progress: 0,
            status: '',
            message: '',
            errors: [],
            done: false,
            parse: function() {
                if (vm.management.file) {
                    vm.management.progress = 1;
                    vm.management.status = 'info';
                    vm.management.message = "clients.clients.sending";
                    vm.management.errors = [];
                    vm.management.done = false;
                    vm.actions = [];
                    Upload.http({
                        url: API_URL + 'api/dhlClients/manage/parse',
                        data: vm.management.file,
                        headers: {
                            "Content-Type": "application/octet-stream"
                        }
                    }).then(function(response) {
                        vm.management.done = true;
                        vm.management.status = 'success';
                        vm.management.message = "clients.clients.finished";
                        response.data.forEach(function(action) {
                            vm.actions.push(action);
                        });
                    }, function(response) {
                        vm.management.done = true;
                        vm.management.status = 'danger';
                        vm.management.message = "clients.clients.errorInFile";
                        vm.management.errors = response.data;
                    },
                    function(event) {
                        vm.management.progress = Math.min(100, parseInt(100.0 *
                            event.loaded / event.total));
                        if (vm.management.progress == 100) {
                            vm.management.status = null;
                            vm.management.message = "clients.clients.processing";
                        }
                    });
                }
            },
            cancel: function() {
                vm.management.progress = 0;
                vm.management.status = '';
                vm.management.message = '';
                vm.management.done = false;
                vm.actions = [];
            },
            succeed: function() {
                vm.management.cancel();
                vm.management.status = 'succeeded';
            },
            fail: function() {
                vm.management.cancel();
                vm.management.status = 'failed';
            },
            manage: function() {
                ClientsService.manage(vm.actions)
                    .then(function(response) {
                        if (response.data == null || response.data.length === 0) {
                            vm.management.succeed();
                        } else {
                            vm.actions = [];
                            vm.management.status = 'danger';
                            vm.management.message = "clients.clients.notSaved";
                            AlertService.error("clients.clients.notCreatedInfo");
                            response.data.forEach(function(action) {
                                vm.actions.push(action);
                            });
                        }
                    })
                    .catch(function(response) {
                        vm.management.fail();
                    });
            }
        };

        //Define a watch for file upload
        $scope.$watch('vm.management.file', function() {
            vm.management.parse();
        });

        //Init controller
        vm.init();

        //Position fix-table
        var fixed = $('#fixed'),
            underFixed = $('#under-fixed');
        $scope.$watch(
            function() {
                return fixed.outerHeight();
            },
            function(newValue) {
                underFixed.css('padding-top', newValue);
            });
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('downloadController', downloadController);

    downloadController.$inject = ['$uibModalInstance', 'ediDocIds', 'language', 'DocumentsService'];

    function downloadController($uibModalInstance, ediDocIds, language, DocumentsService) {
        var vm = this;

        vm.data = {
            downloadPDF : true,
            downloadWaybill : false,
            ediDocIds : ediDocIds
        };

        vm.getPdfsURL = getPdfsURL;

        vm.modal = {
            download: function() {
                $uibModalInstance.close();
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };

        function getPdfsURL(ediDocIds, downloadPDF, downloadWaybill){
            if(isZip(ediDocIds, downloadPDF, downloadWaybill)){
                return DocumentsService.getPdfsURL(ediDocIds, downloadPDF, downloadWaybill, language);
            }else{
                return DocumentsService.getPdfURL(ediDocIds, true, null);
            }
        }

        function isZip(ediDocIds, downloadPDF, downloadWaybill){
            if (downloadPDF == true && downloadWaybill == false && ediDocIds.length === 1) {
                return false;
            }else{
                return true;
            }
        }

    }
})();

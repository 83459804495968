(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('PendingCasesService', PendingCasesService);

    PendingCasesService.$inject = ['API_URL', '$http'];

    function PendingCasesService (API_URL, $http) {
        var service = {
//            getDocument : getDocument,
            getCorrections : getCorrections,
            getClientWaitDocuments: getClientWaitDocuments,
            getNameBySapNumber : getNameBySapNumber,
            getUsers : getUsers,
            usersAccepted : usersAccepted,
            usersDenied : usersDenied,
            correctionsAccepted : correctionsAccepted,
            correctionsDenied : correctionsDenied,
            waitAccepted : waitAccepted,
            waitDenied : waitDenied
        };

        return service;
        function getNameBySapNumber(SapNumber){
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/getName',
                params : {'clientNumber': SapNumber}
            });
        }
        function getCorrections(){
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/pendingInvoices'
            });
        }
        function getClientWaitDocuments(){
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/clientWaitInvoices'
            });
        }
        function getUsers(){
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlUsers/notActive'
            });
        }
        function usersAccepted(UIDs){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlUsers/acceptAll',
                data : UIDs //byc moze nie zalapie nazwy
            });
        }
        function usersDenied(UIDs){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlUsers/rejectAll',
                data : UIDs
            });
        }
        function correctionsAccepted(ediDocIds){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/approveAll',
                data: ediDocIds
            });
        }
        function correctionsDenied(ediDocIds){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/rejectAll',
                data : ediDocIds
            });
        }
        function waitAccepted(ediDocIds){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/approveWait',
                data: ediDocIds
            });
        }
        function waitDenied(ediDocIds){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/rejectWait',
                data : ediDocIds
            });
        }
//        function getDocument(ediDocId){
//            return $http({
//                method: 'GET',
//                url: API_URL + 'api/dhlInvoices/' + ediDocId + '/getPdf',
//                responseType: 'arraybuffer'
//            });
//        }

    }
})();

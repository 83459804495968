(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'application',
                url: '/error',
                views: {
                    'content@application': {
                        templateUrl: 'app/views/error/error.html'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('error');
                        return $translate.refresh();
                    }]
                }
            })
            .state('401', {
                parent: 'error',
                url: '/401',
                data: {
                    authorities: [],
                    pageTitle: 'Nie zalogowano'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/401.html'
                    }
                }
            })
            .state('403', {
                parent: 'error',
                url: '/403',
                data: {
                    authorities: [],
                    pageTitle: 'Brak dostępu'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/403.html'
                    }
                }
            })
            .state('404', {
                parent: 'error',
                url: '/404',
                data: {
                    authorities: [],
                    pageTitle: 'Podana strona nie istnieje'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/404.html'
                    }
                }
            });
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('PasswordResetInit', PasswordResetInit);

    PasswordResetInit.$inject = ['API_URL', '$http'];

    function PasswordResetInit(API_URL, $http) {
        var service = {
            resetPassword: resetPassword
        }

        return service;

        function resetPassword(id, user){
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlUsers/' + id + '/changePassword',
                data: user
            });
        }
    }
})();

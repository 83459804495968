(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .filter('findKeyFromLanguage', findKeyFromLanguage);

    function findKeyFromLanguage() {
        return findKeyFromLanguageFilter;

        function findKeyFromLanguageFilter(lang) {
            return {
                'Català': 'ca',
                'Český': 'cs',
                'Dansk': 'da',
                'Deutsch': 'de',
                'Ελληνικά': 'el',
                'English': 'en',
                'Español': 'es',
                'Français': 'fr',
                'Galego': 'gl',
                'Magyar': 'hu',
                'हिंदी': 'hi',
                'Italiano': 'it',
                '日本語': 'ja',
                '한국어': 'ko',
                'मराठी': 'mr',
                'Nederlands': 'nl',
                'Polski': 'pl',
                'Português (Brasil)': 'pt-br',
                'Português': 'pt-pt',
                'Română': 'ro',
                'Русский': 'ru',
                'Slovenský': 'sk',
                'Svenska': 'sv',
                'தமிழ்': 'ta',
                'Türkçe': 'tr',
                '中文（简体）': 'zh-cn',
                '繁體中文': 'zh-tw'
            }[lang];
        }
    }
})();

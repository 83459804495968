(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password-reset', {
            parent: 'site',
            url: '/password-reset',
            data: {
                pageTitle: 'password-reset.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/views/password-reset/password-reset.html',
                    controller: 'PasswordResetController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password-reset');
                    return $translate.refresh();
                }]
            }
        });
    }
})();

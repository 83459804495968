(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PasswordResetFinishController', PasswordResetFinishController);

    PasswordResetFinishController.$inject = ['$sce', '$rootScope', '$state', 'Auth', 'UsersService', '$stateParams', '$translate'];

    function PasswordResetFinishController($sce, $rootScope, $state, Auth, UsersService, $stateParams, $translate) {
        var vm = this;
        vm.data = {
            password: null,
            repassword: null
        };
        vm.passwordChangeErrorText = null;
        vm.passwordChangeSuccessText = null;
        vm.safePassword = $sce.trustAsHtml($translate.instant('password-reset-finish.toolTips.safePassword'));

        vm.changeType = 1;

        //Declare functions
        vm.init = init;
        vm.resetPassword = resetPassword;
        vm.checkPasswordResetLink = checkPasswordResetLink;
        vm.translate = $translate.instant;
        vm.passwordTooltip = passwordTooltip;
        function passwordTooltip() {
                    return $sce.trustAsHtml($translate.instant('password-reset-finish.toolTips.safePassword'));
                };
        function checkPasswordResetLink() {
            UsersService.checkPasswordResetLink($stateParams.uuid, $stateParams.link).then(function(response) {
                vm.data.acceptReset = true;

                vm.changeType = response.data.message;

            }).catch(function(response) {
                $state.go('404');
            });
        }

        function resetPassword() {
            var pass = {
                password: vm.data.password,
                repassword: vm.data.repassword
            };
            UsersService.resetOwnPassword($stateParams.uuid, $stateParams.link, pass).then(function(response) {
                vm.passwordChangeErrorText = null;
                vm.passwordChangeSuccessText = $translate.instant('password-reset-finish.messages.passwordChangeSuccess');
            }).catch(function(response) {
                vm.passwordChangeSuccessText = null;
                switch (response.data.message) {
                    case 1:
                        vm.passwordChangeErrorText = $translate.instant('password-reset-finish.messages.noFieldsEmpty');
                        break;
                    case 2:
                        vm.passwordChangeErrorText = $translate.instant('global.messages.error.dontmatch');
                        break;
                    case 3:
                        vm.passwordChangeErrorText = $translate.instant('global.messages.validate.newpassword.minlength');
                        break;
                    case 4:
                        vm.passwordChangeErrorText = $translate.instant('password-reset-finish.messages.passwordValidationDescription');
                        break;
                        /*case 5:
                            vm.data.passwordResetErrort = "Hasło musi zawierać co najmninej 3 rodzaje znaków z następujących: małe litery,"+
                            " wielkie litery, cyfry, znaki specjalne";
                            break;*/
                        /*case 6:
                            vm.data.passwordResetError = "Hasło nie może powtarzać ostatnich 12 haseł.";
                            break;*/
                    case 7:
                        vm.passwordChangeErrorText = $translate.instant('password-reset-finish.messages.passwordNoRepeat');
                        break;
                    default:
                        vm.passwordChangeErrorText = $translate.instant('global.error.occurred');
                }
            });
        }

        //Init functions
        function init() {
            vm.checkPasswordResetLink();
        }

        vm.init();
    }
})();

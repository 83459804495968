(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('GenerateURL', GenerateURL);

    GenerateURL.$inject = ['$httpParamSerializer'];

    function GenerateURL ($httpParamSerializer) {
        var service = {
            generate : generate
        };

        return service;

        function generate (baseURL, params) {
           var serializedParams = $httpParamSerializer(params);
           if (serializedParams.length > 0) {
                   baseURL += ((baseURL.indexOf('?') === -1) ? '?' : '&') + serializedParams;
           }

            return baseURL;
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('SystemSettingsModalController', SystemSettingsModalController);

    SystemSettingsModalController.$inject = ['$uibModalInstance'];

    function SystemSettingsModalController($uibModalInstance) {
        var vm = this;

        vm.modal = {
            go: function() {
                $uibModalInstance.close(true);
            },
            cancel: function() {
                $uibModalInstance.close(false);
            }
        };
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('AddRoleController', AddRoleController);

    AddRoleController.$inject = ['$uibModalInstance','RolesService', 'uuid4', 'AlertService', '$translate'];

    function AddRoleController($uibModalInstance, RolesService, uuid4, AlertService, $translate) {
        var vm = this;

        vm.allPermissions = [];
        vm.newRole = {
            description:null,
            name:null,
            sourceName:'DHL',
            allPermissions: []
        };

        //Declare functions
        vm.init = init;
        vm.toggleSelection = toggleSelection;

        //Functions implementations
        vm.modal = {
            add: function() {
                vm.newRole.ediRoleId = uuid4.generate();
                RolesService.createRole(vm.newRole)
                    .then(function(response){
                        RolesService.updateRole(vm.newRole.ediRoleId, vm.newRole)
                            .then(function(response){
                                vm.newRole.result = "added";
                                $uibModalInstance.close(vm.newRole);
                            })
                            .catch(function(response){
                                vm.newRole.result = "warn";
                                $uibModalInstance.close(vm.newRole);
                            });
                    })
                    .catch(function(response){
                        vm.newRole.result = "error";
                        $uibModalInstance.close(vm.newRole);
                    });
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };

        function init(){
            RolesService.getPermissions()
                .then(function(response) {
                    vm.allPermissions = response.data._embedded.ediPermissions;
                })
                .catch(function(response) {
                    AlertService.error("roles.getPermissionsError");
                });
        }

        function toggleSelection(event,index,permission){
            if (event.target.checked){
                vm.newRole.allPermissions.push(permission.ediPermissionId);
            }
            else{
                var i;
                for (i = 0; i <  vm.newRole.permissions.length; ++i) {
                    if( vm.newRole.permissions[i] ==  vm.allPermissions[index].ediPermissionId)
                        vm.newRole.permissions.splice(i, 1);
                }
            }
        }

        vm.init();
    }
})();

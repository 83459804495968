(function() {
    'use strict';

    /*Using this directive requires the table to be wrapped in a DIV element*/
    angular
        .module('edistradadhluiApp')
        .directive("callFunctionAfterNgRepeat", [function() {
            return {
                restrict: 'A',
                scope: false,
                link: function(scope, element, attrs) {
                    if (scope.$last){
                      window.alert("im the last!");
                    }
                }
            };
        }]);
})();

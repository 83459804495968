(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('password-reset-finish', {
            parent: 'application',
            url: '/user/:uuid/set-password/:link',
            data: {
                pageTitle: 'global.menu.settings'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/password-reset-finish/password-reset-finish.html',
                    controller: 'PasswordResetFinishController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('password-reset-finish');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                uuid: ['$stateParams', function($stateParams) {
                    return $stateParams.uuid;
                }],
                link: ['$stateParams', function($stateParams) {
                    return $stateParams.link;
                }]
            }
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ShellService', ShellService);

	ShellService.$inject = ['API_URL','$http'];

    function ShellService (API_URL, $http) {

		return {
			'logout': function(){
				return $http({
				  method: 'POST',
				  url: API_URL + 'api/auth/logout'
				});
			}
		};
	}
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(httpConfig);

    httpConfig.$inject = ['$urlRouterProvider', '$httpProvider', 'httpRequestInterceptorCacheBusterProvider', '$urlMatcherFactoryProvider'];

    function httpConfig($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {
        //enable CSRF
        $httpProvider.defaults.xsrfCookieName = '__Host-CSRF-TOKEN';
        $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

        //Cache everything except REST API requests
        httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*i18n.*/,/.*api.*/], true);

        $urlRouterProvider.otherwise('/');

        // tmp solution no 'home page' currently
        $urlRouterProvider.when('', ['$state', '$match', 'Principal', function($state, $match, Principal) {
            Principal.identity().then(function(response) {
                if (response) {
                    $state.go(Principal.hasInvoiceRead() ? 'documents' : (Principal.hasUserReceivablesAccess() || Principal.hasAdminReceivablesAccess() ? 'receivables' : 'settings'));
                } else {
                    $state.go('login');
                }
            });

        }]);
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        $httpProvider.defaults.headers.common['X-Frame-Options'] = 'DENY';


        $httpProvider.interceptors.push('serviceUnavailableInterceptor');
        $httpProvider.interceptors.push('errorHandlerInterceptor');
        $httpProvider.interceptors.push('authExpiredInterceptor');
        $httpProvider.interceptors.push('notificationInterceptor');

        $urlMatcherFactoryProvider.type('boolean', {
            name: 'boolean',
            decode: function(val) {
                return val === true || val === 'true';
            },
            encode: function(val) {
                return val ? 1 : 0;
            },
            equals: function(a, b) {
                return this.is(a) && a === b;
            },
            is: function(val) {
                return [true, false, 0, 1].indexOf(val) >= 0;
            },
            pattern: /bool|true|0|1/
        });
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ConstantsService', ConstantsService);

    ConstantsService.$inject = ['API_URL', '$http'];

    function ConstantsService(API_URL, $http) {
        var service = {
            set: set,
            get: get
        };

        return service;

        function set(interval) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlConstants/set',
                data: interval
            });
        }

        function get(constantName) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlConstants/search/findByName',
                params: { name: constantName }
            });
        }

    }
})();

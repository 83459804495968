(function() {
    'use strict';

    /*Using this directive requires the table to be wrapped in a DIV element*/
    angular
        .module('edistradadhluiApp')
        .directive("dynamicHeight", ['$compile', function($compile) {
            return {
                restrict: 'A',
                scope: {
                    dhElementId: '@'
                },
                link: function(scope, element, attrs) {
                    scope.$watch(function(){ //watch any changes to our element
                        $(scope.dhElementId).css('padding-top', element[0].offsetHeight);
                    });

                    scope.$on('alert-service-event', function(evt, data) {
                        $(scope.dhElementId).css('padding-top', element[0].offsetHeight);
                    });
                }
            };
        }]);
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('RolesController', RolesController);

    RolesController.$inject = ['$uibModal', 'RolesService', 'AlertService'];

    function RolesController($uibModal, RolesService, AlertService) {
        var vm = this;

        vm.roles = [];

        vm.init = init;
        vm.goToAddRole = goToAddRole;
        vm.goToEditRole = goToEditRole;

        function goToAddRole() {
            var newUserModal = $uibModal.open({
                templateUrl: 'app/views/roles/addRole.html',
                controller: 'AddRoleController',
                controllerAs: 'vm',
                size: 'md'
            });
            newUserModal.result.then(function(response) {
                if (response.result === 'added') {
                    delete response.result;
                    vm.init();
                } else if (response.result === 'warn') {
                    delete response.result;
                    vm.init();
                    AlertService.error("roles.assignError");
                } else if (response.result === 'error') {
                    delete response.result;
                    AlertService.error("roles.addingError");
                }
            });
        }

        function goToEditRole(event, index, role) {
            var newUserModal2 = $uibModal.open({
                templateUrl: 'app/views/roles/editRole.html',
                controller: 'EditRoleController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    passRole: role
                }
            });

            newUserModal2.result.then(function(response) {
                if (response.result === 'updated' || response.result === 'deleted') {
                    delete response.result;
                    vm.init();
                } else if (response.result === 'error') {
                    delete response.result;
                    AlertService.error("roles.updateError");
                }
            });
        }

        function init() {
            RolesService.getRoles()
                .then(function(response) {
                    vm.roles = response.data._embedded.ediRoles;
                })
                .catch(function(response) {
                    AlertService.error("roles.getError");
                });
        }

        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('RegisterController', RegisterController);

    RegisterController.$inject = ['$scope', '$state', '$stateParams', 'UsersService', '$animate', 'uuid4', '$sce','$translate'];

    function RegisterController($scope, $state, $stateParams, UsersService, $animate, uuid4, $sce, $translate) {
        var vm = this;
        vm.translate = $translate.instant;
        $scope.invoiceNumberTooltip = $sce.trustAsHtml($translate.instant('register.invoiceNumberTooltip'));

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.data = {
            uuid: null,
            email: null,
            nip: null,
            invoiceNr: null,
            totalAmount: null,
            clientCode: null
        };
        vm.uuid = null;

        //Declare functions
        vm.init = init;
        vm.send = send;
        vm.invalid = invalid;

        //Init functions
        function init() {
            vm.data.uuid = uuid4.generate();
        }

        function send() {
            vm.registerErrorText = null;

            var registerData = {
                uuid: vm.data.uuid,
                email: vm.data.email,
                nip: vm.data.nip,
                invoiceNumber: vm.data.invoiceNr,
                grossAmount: parseFloat(vm.data.totalAmount.replace(',', '.')),
                clientCode: vm.data.clientCode
            };

            UsersService.createNewUser(registerData).then(function(response) {
                $state.go('register_end');
            }).catch(function(response) {


            });
        }


         function invalid() {
            vm.registerErrorText=$translate.instant("register.registerErrorText");
            if($( "#dhlEmail" ).hasClass( "ng-invalid" ))
                $( "#dhlEmailLabel" ).addClass("red")
            else
                $( "#dhlEmailLabel" ).removeClass("red")

            if($( "#dhlNip" ).hasClass( "ng-invalid" ))
                $( "#dhlNipLabel" ).addClass("red")
            else
                $( "#dhlNipLabel" ).removeClass("red")

            if($( "#dhlInvoice" ).hasClass( "ng-invalid" ))
                $( "#dhlInvoicedhlLabel" ).addClass("red")
            else
                $( "#dhlInvoicedhlLabel" ).removeClass("red")

            if($( "#dhlGrossAmount" ).hasClass( "ng-invalid" ))
                $( "#dhlGrossAmountLabel" ).addClass("red")
            else
                $( "#dhlGrossAmountLabel" ).removeClass("red")

            if($( "#dhlClientCode" ).hasClass( "ng-invalid" ))
                $( "#dhlClientCodeLabel" ).addClass("red")
            else
                $( "#dhlClientCodeLabel" ).removeClass("red")
         }
        //Init controller
        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('PdfViewService', PdfViewService);

    PdfViewService.$inject = ['API_URL', '$http', 'GenerateURL'];

    function PdfViewService(API_URL, $http, GenerateURL) {
        var service = {
            getFirstPagePdf: getFirstPagePdf,
            getInvoiceStylesheet: getInvoiceStylesheet
        };

        return service;





        function getInvoiceStylesheet(EdiDocId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/' + EdiDocId + '/getInvoiceStylesheet'
            });
        }

        function getFirstPagePdf(EdiDocId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/' + EdiDocId + '/getFirstPagePdf',
                responseType: 'arraybuffer'
            });
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('gross', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {
            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input id='dhlGrossAmount' name='dhlGrossAmount' class='form-control register-input normal-form' required='required' ng-model='vm.data.totalAmount' type='text' ng-class='registerGrossAmount' smart-float ng-pattern='/^[+-]?[0-9]+([.,][0-9]{1,2})?$/' uib-tooltip='{{grossTooltip()}}'  tooltip-placement='top' />",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    //Only numbers
                    control.$parsers.push(function(val) {
                        if (angular.isUndefined(val)) {
                            var val = '';
                        }
                        var clean = val.replace(/[^0-9,.-]+/g, '');
                        if (val !== clean) {
                            control.$setViewValue(clean);
                            control.$render();
                        }
                        return clean;
                    });

                    $(element).bind('keypress', function(event) {
                        if (event.keyCode === 32) {
                            event.preventDefault();
                        }
                    });
                    //Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);
                    // Begins
                    control.$validators.format = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[+-]?[0-9]+([.,][0-9]{1,2})?$/.test(value));
                    }, element);

                    scope.grossTooltip = function() {
                        var tooltipGross = '';
                        if (control.$error.required)
                            tooltipGross = $translate.instant("directives.giveValidTotal");
                        else if (control.$error.format)
                            tooltipGross = $translate.instant("directives.invalidTotal");
                        return tooltipGross;
                    }

                    $(element).on('keyup change ', function(event) {
                        if ($("#dhlGrossAmount").hasClass("ng-invalid"))
                            $("#dhlGrossAmountLabel").addClass("red")
                        else
                            $("#dhlGrossAmountLabel").removeClass("red")
                    });
                }
            }
        }]);
})();

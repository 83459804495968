(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('edistradadhluiApp')
        .constant('VERSION', "130.0.0-1-gd7982e7")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('API_URL', "https://dhl.pl.qas.edistrada.pl/edistrada2/")
;
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(dateFormatConfig);

    dateFormatConfig.$inject = ['$mdDateLocaleProvider'];

    function dateFormatConfig($mdDateLocaleProvider) {
         $mdDateLocaleProvider.formatDate = function(date) {

            if (date!=null){
                  var day = date.getDate();
                  var monthIndex = date.getMonth();
                  var year = date.getFullYear();

                  return year + '-' + ((monthIndex + 1 < 10 ? '0':'') + (monthIndex + 1)) + '-' + ((day < 10 ? '0':'')+day)  ;
            } else {
                 return "";
            }
         };
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['$scope', '$state', '$uibModal', 'DocumentsService', 'Principal', 'AlertService', '$filter', '$translate', '$timeout'];

    function DocumentsController($scope, $state, $uibModal, DocumentsService, Principal, AlertService, $filter, $translate, $timeout) {
        var vm = this;

        vm.Principal = Principal;

        //Declare variables
        vm.tempIssueDateFrom = null;
        vm.tempIssueDateTo = null;
        vm.tempDueDate = null;
        vm.translate = $translate.instant;

        vm.window = $(window);
        vm.table = $("#fixtable[fix-table]");
        vm.topSpacer = $('#topSpacer').find("td");
        vm.bottomSpacer = $('#bottomSpacer').find("td");
        vm.topIndex = 0;
        vm.height = 31;
        vm.limit = Math.max(Math.ceil(vm.window.height() / vm.height) + 10, 50);

        vm.window.resize(function() {
            vm.height = vm.table.find("td").eq(2).outerHeight();
            vm.limit = Math.ceil(vm.window.height() / vm.height) + 10;
            vm.window.trigger("scroll");
        });

        var windowScrollTimeout;
        vm.window.scroll(function() {
            $timeout.cancel(windowScrollTimeout);

            windowScrollTimeout = $timeout(function() {
                var numberOfRows = Math.floor((vm.window.scrollTop() - vm.table.offset().top) / vm.height);
                vm.topIndex = Math.max(0, numberOfRows % 2 !== 0 ? numberOfRows + 1 : numberOfRows);
            }, 20);
        });

        $scope.$watchGroup(["vm.topIndex", "vm.height"], resizePlaceholders);

        $scope.$watchCollection("vm.invoices", resizePlaceholders);

        function resizePlaceholders() {
            vm.topSpacer.height(vm.topIndex * vm.height);
            vm.bottomSpacer.height(vm.invoices.length < vm.limit ? 0 : (vm.invoices.length - vm.limit - vm.topIndex) * vm.height);
        }

        vm.columnHeaders = [{
            name: "clientNumber",
            enabled: true,
            label: "documents.document.customer"
        }, {
            name: "payerNumber",
            enabled: true,
            label: "documents.document.payer"
        }, {
            name: "dateOpen",
            enabled: Principal.hasUserRead(),
            label: "documents.document.open"
        }, {
            name: "documentType",
            enabled: true,
            label: "documents.document.docType"
        }, {
            name: "documentNumber",
            enabled: true,
            label: "documents.document.docNumber"
        }, {
            name: "dateIssue",
            enabled: true,
            label: "documents.document.issueDate"
        }, {
            name: "dateSent",
            enabled: true,
            label: "documents.document.shippingDate"
        }, {
            name: "dueDate",
            enabled: true,
            label: "documents.document.dateOfPayment"
        }, {
            name: "totalNetto",
            enabled: true,
            label: "documents.document.netAmount"
        }, {
            name: "totalVat",
            enabled: true,
            label: "documents.document.vatAmount"
        }, {
            name: "totalGross",
            enabled: true,
            label: "documents.document.grossAmount"
        }];

        vm.selectedColumns = [];

        $scope.onClose = function() {
            var footerSum = 1;
            var totalVisible = 1;
            vm.columnHeaders.forEach(function(element, index) {
                if (vm.selectedColumns.indexOf(element) !== -1) {
                    vm.columns[element.name] = true;
                    totalVisible++;
                    if (element.name.indexOf("total") === -1)
                        footerSum++;
                } else vm.columns[element.name] = false;
            });
            vm.columns.footerSum = footerSum;
            vm.columns.totalVisible = totalVisible;
            vm.columns.footer = totalVisible - footerSum > 0;

            DocumentsService.setUserColumnConf(Principal.getProperty('userId'), vm.columns);
        };

        vm.filter = {
            clientNo: null,
            payerNo: null,
            clientName: null,
            documentNoFrom: null,
            documentNoTo: null,
            waybillNo: null,
            issueDateFrom: null,
            issueDateTo: null,
            documentType: null,
            amountBruttoFrom: null,
            amountBruttoTo: null,
            dueDate: null,
            limit: 1000
        };

        vm.columns = {
            clientNumber: true,
            payerNumber: true,
            dateOpen: Principal.hasUserRead(),
            documentType: true,
            documentNumber: true,
            dateIssue: true,
            dateSent: true,
            dueDate: true,
            totalNetto: true,
            totalVat: true,
            totalGross: true,
            footer: true,
            footerSum: Principal.hasUserRead() ? 9 : 8,
            totalVisible: Principal.hasUserRead() ? 12 : 11
        };

        vm.ediDocIds = [];
        vm.invoices = [];
        vm.resultParameters = {};
        vm.sortType = 'dateIssue';
        vm.sortReverse = false;
        vm.sort = '';
        vm.sortBy = sortBy;
        vm.sumTotalNetto = 0;
        vm.sumTotalBrutto = 0;
        vm.sumTotalVat = 0;
        vm.allChecked = false;
        vm.showCarretOnOpenColumn = false;

        //Declare functions
        vm.init = init;
        vm.list = list;
        vm.checkboxClick = checkboxClick;
        vm.goToPDF = goToPDF;
        vm.getPDFs = getPDFs;
        vm.resend = resend;
        vm.resetAndList = resetAndList;
        //        vm.getXlsx = getXlsx;
        vm.showAlertAfterDocumentSend = showAlertAfterDocumentSend;
        vm.getAllDocumentNumbersAsString = getAllDocumentNumbersAsString;
        vm.getDocumentNumber = getDocumentNumber;
        vm.calculateSums = calculateSums;
        vm.checkAllDocs = checkAllDocs;
        vm.resetSearch = resetSearch;
        vm.parseClientPayerNumberToInt = parseClientPayerNumberToInt;
        vm.getUserColumnConf = getUserColumnConf;
        vm.filterInvoiceType = filterInvoiceType;
        vm.filterDateOpen = filterDateOpen;
        vm.reformatDate = reformatDate;
        vm.comparator = comparator;
        vm.checkShowColumnOpen = checkShowColumnOpen;
        vm.reformatDates = reformatDates;
        vm.getXlsxURL = getXlsxURL;

        function goToPDF(ediDoc) {
            var url = $state.href('pdfView', { uuid: ediDoc });
            window.open(url, '_blank');
        }

        function resetSearch() {
            vm.filter.clientNo = null;
            vm.filter.payerNo = null;
            vm.filter.clientName = null;
            vm.filter.documentNoFrom = null;
            vm.filter.documentNoTo = null;
            vm.filter.waybillNo = null;
            vm.filter.issueDateFrom = null;
            vm.filter.issueDateTo = null;
            vm.filter.documentType = null;
            vm.filter.amountBruttoFrom = null;
            vm.filter.amountBruttoTo = null;
            vm.filter.dueDate = null;
            vm.tempIssueDateFrom = null;
            vm.tempIssueDateTo = null;
            vm.tempDueDate = null;
        }

        function checkShowColumnOpen() {
            vm.showCarretOnOpenColumn = angular.equals(['openStatus', 'dateOpen'], vm.sortType);
        }

        function comparator(value1, value2) {
            if (value1.value == value2.value)
                return 0;
            else {
                return value1.value < value2.value ? 1 : -1;
            }
        }

        function resend() {

            AlertService.clear();

            var newUserModal = $uibModal.open({
                templateUrl: 'app/views/documents/resend.html',
                controller: 'resendController',
                controllerAs: 'vm',
                size: 'md'
            });

            newUserModal.result.then(function( /*result*/ response) {
                if (response.email == null /*'same'*/ ) {
                    delete response.result;
                    DocumentsService.resendEmailForAllDocuments(vm.ediDocIds)
                        .then(function(response) {
                            showAlertAfterDocumentSend(response);
                            list(vm.filter);
                        }).catch(function(response) {
                            AlertService.error("documents.messages.notificationFail");
                        });
                } else {
                    delete response.result;
                    var emailArray = response.email.split(";");

                    DocumentsService.resendEmailOnNewAdresses(vm.ediDocIds, emailArray)
                        .then(function(response) {
                            showAlertAfterDocumentSend(response);
                            list(vm.filter);
                        }).catch(function(response) {
                            AlertService.error("documents.messages.notificationFail");
                        });
                }
            });
        }

        function showAlertAfterDocumentSend(response) {
            if (response.data != null) {
                if (response.data.failed !== null && Object.keys(response.data.failed).length > 0) {
                    for (var key in response.data.failed) {
                        if (response.data.failed.hasOwnProperty(key)) {
                            var message = response.data.failed[key].message;
                            if (message == "document.client.not.found") {
                                AlertService.error("documents.messages.clientNotFound", { client: response.data.failed[key].atrList[0], docNum: response.data.failed[key].atrList[1] });
                            } else {
                                AlertService.error(message);
                            }
                        } else {
                            AlertService.error("documents.message.notificationFail");
                        }
                    }
                }

                if (response.data.success !== null && response.data.success.length > 0 && response.data.failed !== null && Object.keys(response.data.failed).length > 0) {
                    AlertService.success('documents.messages.notificationsSentFor', {count: response.data.success.length, docs: getAllDocumentNumbersAsString(response.data.success)} );
                } else if (response.data.success !== null && response.data.success.length > 0) {
                    AlertService.success('documents.messages.notificationsSent');
                } else if ((response.data.failed == null || Object.keys(response.data.failed).length === 0) && (response.data.success == null || Object.keys(response.data.success).length === 0)) {
                    AlertService.error('documents.messages.notificationFail');
                }
            }
        }

        function getAllDocumentNumbersAsString(ids) {
            var msg = '';
            var tmp;
            for (var id in ids) {
                msg += ((tmp = getDocumentNumber(ids[id])) !== null ? (tmp + '; ') : '');
            }
            return msg;
        }

        function getDocumentNumber(ediDocId) {
            for (var invoice in vm.invoices) {
                if (ediDocId == vm.invoices[invoice].ediDocId) {
                    return vm.invoices[invoice].documentNumber;
                }
            }
            return null;
        }

        function checkboxClick(event, index, ediDocId) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.ediDocIds.push(ediDocId);
            } else {
                var i;
                for (i = 0; i < vm.ediDocIds.length; ++i) {
                    if (vm.ediDocIds[i] == ediDocId)
                        vm.ediDocIds.splice(i, 1);
                }
            }
        }

        function checkAllDocs(event) {
            event.stopPropagation();
            if (vm.allChecked) {
                vm.ediDocIds = [];
                angular.forEach(vm.invoices, function(item) {
                    item.selected = false;
                });
                vm.allChecked = false;
            } else {
                vm.ediDocIds = [];
                angular.forEach(vm.invoices, function(item) {
                    item.selected = true;
                    vm.ediDocIds.push(item.ediDocId);
                });
                vm.allChecked = true;
            }
        }

        function getPDFs() {
            var language = angular.element('#lang').controller().getLanguage();

            $uibModal.open({
                templateUrl: 'app/views/documents/download.html',
                controller: 'downloadController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    ediDocIds: function() {
                        return vm.ediDocIds;
                    },
                    language: function() {
                        return language;
                    }
                }
            });
        }

        function getXlsxURL() {
            var listFilter = angular.copy(vm.filter);
            listFilter.sortBy = String(vm.sortType);
            listFilter.reverse = vm.sortReverse;
            listFilter.columnsFromJson = vm.columns;
            reformatDates();
            return DocumentsService.getXlsxURL(listFilter);
        }

        function init() {
            if(!vm.Principal.hasAllDocumentsAccess())
                list(vm.filter);

            getUserColumnConf();
            calculateSums();
        }

        function list(filter) {
            reformatDates();
            vm.ediDocIds = [];
            var listFilter = angular.copy(filter);
            listFilter.sortBy = String(vm.sortType);
            listFilter.reverse = vm.sortReverse;
            listFilter.columnsFromJson = null;
            DocumentsService.list(listFilter)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    if (response.data._embedded != null && response.data._embedded.dhlInvoices != null) {
                        vm.invoices = response.data._embedded.dhlInvoices;
                        vm.resultParameters = response.headers();

                        if(vm.resultParameters.upper_date_bound)
                            vm.tempIssueDateTo = new Date(parseInt(vm.resultParameters.upper_date_bound));
                        if(vm.resultParameters.lower_date_bound)
                            vm.tempIssueDateFrom = new Date(parseInt(vm.resultParameters.lower_date_bound));

                    } else {
                        AlertService.error('documents.search.notFound');
                        vm.invoices = [];
                    }
                })
                .finally(function(response) {
                    calculateSums();
                    parseClientPayerNumberToInt();
                });
        }

        function calculateSums() {
            vm.sumTotalNetto = 0.00;
            vm.sumTotalBrutto = 0.00;
            vm.sumTotalVat = 0.00;
            angular.forEach(vm.invoices, function(invoice) {
                if (invoice.totalNetto != null) {
                    vm.sumTotalNetto += parseFloat(invoice.totalNetto);
                }
                if (invoice.totalGross != null) {
                    vm.sumTotalBrutto += parseFloat(invoice.totalGross);
                }
                if (invoice.totalVat != null) {
                    vm.sumTotalVat += parseFloat(invoice.totalVat);
                }
            });
            vm.sumTotalNetto = vm.sumTotalNetto.toFixed(2);
            vm.sumTotalBrutto = vm.sumTotalBrutto.toFixed(2);
            vm.sumTotalVat = vm.sumTotalVat.toFixed(2);
        }

        function parseClientPayerNumberToInt() {
            angular.forEach(vm.invoices, function(invoice) {
                invoice.clientNumber = parseInt(invoice.clientNumber);
                invoice.payerNumber = parseInt(invoice.payerNumber);
            });
        }

        function getUserColumnConf() {
            DocumentsService.getUserColumnConf(Principal.getProperty('userId'))
                //If successful
                .then(function(response) {
                    for (var property in response.data)
                        vm.columns[property] = JSON.parse(response.data[property]);

                    if (!vm.columns.footerSum)
                        vm.columns.footerSum = Principal.hasUserRead() ? 9 : 8;
                    if (!vm.columns.totalVisible)
                        vm.columns.totalVisible = Principal.hasUserRead() ? 12 : 11;

                    vm.columns.dateOpen = Principal.hasUserRead() ? vm.columns.dateOpen : false;

                    for (var column in vm.columns)
                        if (vm.columns[column] === true)
                            for (var columnHeader in vm.columnHeaders)
                                if (vm.columnHeaders[columnHeader].name === column)
                                    vm.selectedColumns.push(vm.columnHeaders[columnHeader]);
                });
        }

        function reformatDates() {
            if (vm.tempIssueDateFrom != null)
                vm.filter.issueDateFrom = reformatDate(vm.tempIssueDateFrom);
            if (vm.tempIssueDateTo != null)
                vm.filter.issueDateTo = reformatDate(vm.tempIssueDateTo);
            if (vm.tempDueDate != null)
                vm.filter.dueDate = reformatDate(vm.tempDueDate);
        }

        function reformatDate(date) {
            var result = '';


            result += date.getYear() + 1900;
            result += '-';

            result += date.getMonth() + 1;
            result += '-';
            result += date.getDate();
            return result;
        }

        function filterInvoiceType(type) {
            switch ($filter('uppercase')(type)) {
                case 'INVOICE':
                    return $translate.instant('documents.document.invoice');
                case 'CORRECTION':
                    return $translate.instant('documents.document.correctingInvoice');
                case 'NOTE':
                    return $translate.instant('documents.document.accountingNote');
                default:
                    return type;
            }
        }

        function filterDateOpen(status, date) {
            if (date == null) {
                if (status != null) {
                    return status;
                } else {
                    return $translate.instant('documents.document.notOpened');
                }
            } else {
                return date.substring(0, date.indexOf('T'));
            }
        }

        function sortBy(column, reverse){
            vm.sortType = column;
            vm.sortReverse = reverse;
            vm.checkShowColumnOpen();
            vm.list(vm.filter);
        }

        function resetAndList(){
            AlertService.clear();
            vm.list(vm.filter);
        }

        //Init controller
        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('UserReportController', UserReportController);

    UserReportController.$inject = ['$uibModalInstance', '$translate', 'UsersService', 'UtilService'];

    function UserReportController($uibModalInstance, $translate, UsersService, UtilService) {
        var vm = this;
        vm.translate = $translate.instant;
        vm.filter = {};
        vm.modal = {
            generate: function() {
                $uibModalInstance.close(vm.data);
            },
            getGenerateURL: function(){
                var filterToSend = angular.copy(vm.filter);
                if (vm.active == 0)
//                    UsersService.usersReport(vm.filter)
//                    .then(function(response) {
//                        var date = new Date();
//                        FileSaver.saveAs(new Blob([response.data], { type: "text/csv" }),
//                            $translate.instant("users.report.listPrefix") + (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()) + ".xlsx");
//                    });
                    return UsersService.getUsersReportURL(filterToSend);
                else {

                    if (UtilService.isFilled(vm.filter.activityDateFrom))
                        filterToSend.activityDateFrom = new Date(vm.filter.activityDateFrom);

                    if (UtilService.isFilled(vm.filter.activityDateTo)) {
                        var activityDateTo = new Date(vm.filter.activityDateTo);
                        activityDateTo.setHours(23);
                        activityDateTo.setMinutes(59);
                        activityDateTo.setSeconds(59);
                        filterToSend.activityDateTo = activityDateTo;
                    }

                    if (UtilService.isFilled(vm.filter.activityUserTypes))
                        filterToSend.activityUserTypes = vm.filter.activityUserTypes.split(",");

                    if (UtilService.isFilled(vm.filter.activityOperators))
                        filterToSend.activityOperators = vm.filter.activityOperators.split(",");

                    if (UtilService.isFilled(vm.filter.activityUserEmails))
                        filterToSend.activityUserEmails = vm.filter.activityUserEmails.split(",");

                    if (UtilService.isFilled(vm.filter.activityClientNames))
                        filterToSend.activityClientNames = vm.filter.activityClientNames.split(",");

                    if (UtilService.isFilled(vm.filter.activityClientNumbers))
                        filterToSend.activityClientNumbers = vm.filter.activityClientNumbers.split(",");

                    if (UtilService.isFilled(vm.filter.activityDocumentNumbers))
                        filterToSend.activityDocumentNumbers = vm.filter.activityDocumentNumbers.split(",");

                    if (UtilService.isFilled(vm.filter.useArchiveUsers))
                        filterToSend.useArchiveUsers = vm.filter.useArchiveUsers;

//                    UsersService.activityReport(vm.filter)
//                        .then(function(response) {
//                            var date = new Date();
//                            FileSaver.saveAs(new Blob([response.data], { type: "text/csv" }),
//                                $translate.instant("users.report.activitiesPrefix") + (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()) + ".xlsx");
//                        });
                    return UsersService.getActivityReportURL(filterToSend);
                }
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };

        setTimeout(function() {
            $('#actionTypeMultiselect').multiselect({
                includeSelectAllOption: true,
                dropUp: true,
                buttonText: function(options, select) {
                    if (options.length === 11) {
                        return $translate.instant("users.report.actions.all");
                    } else if (options.length === 0) {
                        return $translate.instant("users.report.actions.choose");
                    } else if (options.length % 10 === 1) {
                        return $translate.instant("users.report.actions.chosen") + options.length + $translate.instant("users.report.actions.1action");
                    } else if (options.length % 10 > 1 && options.length % 10 < 5) {
                        return $translate.instant("users.report.actions.chosen") + options.length + $translate.instant("users.report.actions.2actions");
                    } else if (options.length % 10 > 4) {
                        return $translate.instant("users.report.actions.chosen") + options.length + $translate.instant("users.report.actions.5actions");
                    }
                },
                selectAllText: $translate.instant("users.report.selectAll")
            });
        }, 1);
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('SystemSettingsController', SystemSettingsController);

    SystemSettingsController.$inject = ['$scope', '$translate', 'ConstantsService', 'Upload', 'API_URL', 'MailTemplatesService', '$uibModal', 'FileSaver', 'Blob', 'UtilService', 'filterFilter', 'ClientsService'];

    function SystemSettingsController($scope, $translate, ConstantsService, Upload, API_URL, MailTemplatesService, $uibModal, FileSaver, Blob, UtilService, filterFilter, ClientsService) {
        var vm = this;
        vm.translate = $translate.instant;
        vm.ActivityTypes = [{
            name: 'ACCOUNT_OPENED',
            text: function() {
                return $translate.instant('systemSettings.templateType.open');
            }
        }, {
            name: 'EMAIL_ADDED',
            text: function() {
                return $translate.instant('systemSettings.templateType.emailAdded');
            }
        }, {
            name: 'REGISTRATION_FAIL',
            text: function() {
                return $translate.instant('systemSettings.templateType.invalidRegistration');
            }
        }, {
            name: 'PASSWORD_CHANGE',
            text: function() {
                return $translate.instant('systemSettings.templateType.reset');
            }
        }, {
            name: 'PAPER_INVOICE',
            text: function() {
                return $translate.instant('systemSettings.templateType.paperInvoice');
            }
        }, {
            name: 'INVOICE_WITH_LINK',
            text: function() {
                return $translate.instant('systemSettings.templateType.invoiceLink');
            }
        }, {
            name: 'INVOICE_WITH_PDF',
            text: function() {
                return $translate.instant('systemSettings.templateType.invoicePdf');
            }
        }, {
            name: 'INVOICE_WITH_LINK_AND_XLSX',
            text: function() {
                return $translate.instant('systemSettings.templateType.invoiceLinkAndXlsx');
            }
        }, {
            name: 'INVOICE_WITH_PDF_AND_XLSX',
            text: function() {
                return $translate.instant('systemSettings.templateType.invoicePdfAndXlsx');
            }
        }, {
            name: 'CORRECTION_INVOICE',
            text: function() {
                return $translate.instant('systemSettings.templateType.correctingInvoice');
            }
        }, {
            name: 'INVOICE_TOO_LARGE',
            text: function() {
                return $translate.instant('systemSettings.templateType.tooBig');
            }
        }];
        vm.languages = [{
            name: 'pl',
            text: function() {
                return $translate.instant('systemSettings.templateLanguage.pl');
            }
        }, {
            name: 'en',
            text: function() {
                return $translate.instant('systemSettings.templateLanguage.en');
            }
        }];
        vm.ifContinue = true;
        vm.dhlTemplates = [];
        vm.dhlTitles = [];
        vm.dhlEmailTempaltes = null;
        vm.dhlEmailTempaltesIds = null;
        vm.templatesToSend = [];
        vm.clientId = null;
        vm.dhlClient = null;
        vm.addingEmailTemplateErrorText = null;
        vm.addingEmailTemplateSuccessText = null;
        vm.getDataErrorText = null;
        vm.clientErrorText = null;
        vm.clientSuccessText = null;
        vm.htmlTooltip = false;
        vm.chooseOrDrop = chooseOrDrop;

        vm.client = {
            templates: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titles: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            templatesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            }
        };


        vm.upload = upload;
        vm.submit = submit;
        vm.chectUniqueNameAndType = chectUniqueNameAndType;
        vm.getTitleTemplates = getTitleTemplates;
        vm.getContentTemplates = getContentTemplates;
        vm.download = download;
        vm.addClientNumber = addClientNumber;
        vm.addInvoiceNumber = addInvoiceNumber;
        vm.addInvoiceDate = addInvoiceDate;
        vm.addNettoValue = addNettoValue;
        vm.checkTemplateTitle = checkTemplateTitle;
        vm.addTemplateTitle = addTemplateTitle;
        vm.clearForms = clearForms;
        vm.getAllEmailTemplates = getAllEmailTemplates;
        vm.getUserTemplatesId = getUserTemplatesId;
        vm.sendNewUserTemplates = sendNewUserTemplates;
        vm.getCommonElementFromLists = getCommonElementFromLists;
        vm.getClientByNumber = getClientByNumber;
        vm.changeActionTypeToText = changeActionTypeToText;
        vm.setTooltip = setTooltip;
        vm.disableTooltip = disableTooltip;


        vm.init = function() {
            vm.interval.get();
            vm.showInterval.get();
            vm.getContentTemplates();
            vm.getTitleTemplates();
            vm.clearForms();
        };

        function clearForms() {
            vm.template = {
                activity: vm.ActivityTypes[0],
                name: null,
                language: vm.languages[0]
            };
            vm.title = {
                activity: vm.ActivityTypes[0],
                name: null,
                text: "",
                language: vm.languages[0]
            };
        }

        vm.interval = {
            current: null,
            new: null,
            measure: "days",
            set: function() {
                var interval = vm.interval.new;
                switch (vm.interval.measure) {
                    case "days":
                        interval *= 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "months":
                        interval *= 30 /*days*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "years":
                        interval *= 365 /*days*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                }
                ConstantsService.set({
                    name: 'PASSWORD_CHANGE_INTERVAL',
                    value: interval
                })
                .then(function(response) {
                    vm.interval.get();
                });
            },
            get: function() {
                ConstantsService.get('PASSWORD_CHANGE_INTERVAL')
                    .then(function(response) {
                        var interval = response.data.value;
                        //Convert to days
                        interval = interval / 1000 /*ms*/ / 60 /*s*/ / 60 /*min*/ / 24 /*h*/ ;
                        vm.interval.current = interval;
                    });
            }
        };

        vm.showInterval = {
            current: null,
            new: null,
            measure: "days",
            set: function() {
                var interval = vm.showInterval.new;
                switch (vm.showInterval.measure) {
                    case "days":
                        interval *= 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "months":
                        interval *= 30 /*days*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "years":
                        interval *= 365 /*days*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                }
                ConstantsService.set({
                    name: 'PASSWORD_SHOW_CHANGE_INTERVAL',
                    value: interval
                })
                .then(function(response) {
                    vm.showInterval.get();
                });
            },
            get: function() {
                ConstantsService.get('PASSWORD_SHOW_CHANGE_INTERVAL')
                    .then(function(response) {
                        var interval = response.data.value;
                        //Convert to days
                        interval = interval / 1000 /*ms*/ / 60 /*s*/ / 60 /*min*/ / 24 /*h*/ ;
                        vm.showInterval.current = interval;
                    });
            }
        };

        function submit(file) {
            if (typeof file !== "undefined" && file) {
                vm.upload(file);
            } else {
                vm.addingEmailTemplateErrorText = $translate.instant('systemSettings.messages.choseFile');
                vm.addingEmailTemplateSuccessText = null;
            }
        }

        // upload on file select or drop
        function upload(file) {
            Upload.upload({
                url: API_URL + 'api/dhlTemplates/upload',
                fields: { 'name': vm.template.name, 'type': vm.template.activity, 'language': vm.template.language },
                file: file
            }).then(function(resp) {
                vm.getContentTemplates();
                vm.clearForms();
                vm.addingEmailTemplateSuccessText = $translate.instant('systemSettings.messages.templateSuccess');
                vm.addingEmailTemplateErrorText = null;
            }, function(resp) {
                vm.addingEmailTemplateErrorText = $translate.instant('error.occurred');
                vm.addingEmailTemplateSuccessText = null;
            });
        }

        function chectUniqueNameAndType(file, size) {
            MailTemplatesService.checkUnique(vm.template.name, vm.template.activity, vm.template.language, 'EMAIL_CONTENT').then(function(response) {
                vm.submit(file);
            }).catch(function(response) {
                if (!response.data.unique) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'app/views/system-settings/template-upload-modal/system-settings.modal.html',
                        controller: 'SystemSettingsModalController',
                        controllerAs: 'vm',
                        size: size
                    });

                    modalInstance.result.then(function(go) {
                        if (go) {
                            vm.submit(file);
                        }
                    });
                } else {
                    vm.addingEmailTemplateErrorText = $translate.instant('error.occurred');
                    vm.addingEmailTemplateSuccessText = null;
                }
            });
        }

        function getContentTemplates() {
            MailTemplatesService.getTemplatesByType("EMAIL_CONTENT").then(function(response) {
                vm.dhlTemplates = response.data;
            }).catch(function(response) {
                vm.getDataErrorText = $translate.instant('systemSettings.dataError');
            });
        }

        function getTitleTemplates() {
            MailTemplatesService.getTemplatesByType("EMAIL_TITLE").then(function(response) {
                vm.dhlTitles = response.data;
            }).catch(function(response) {
                vm.getDataErrorText = $translate.instant('systemSettings.dataError');
            });
        }

        function download(name, type, language, filename) {
            MailTemplatesService.download(name, type, language).then(function(response) {
                FileSaver.saveAs(new Blob([response.data], { type: "text/html" }), filename);
            }).catch(function(response) {
                //blad pobrania danych
            });
        }

        function addClientNumber() {
            if (vm.title.text) {
                vm.title.text = [vm.title.text.slice(0, $('#titleArea').prop("selectionStart")), "{{clientNumber}}",
                    vm.title.text.slice($('#titleArea').prop("selectionStart"))
                ].join('');
            } else {
                vm.title.text = "{{clientNumber}}";
            }
        }

        function addInvoiceNumber() {
            if (vm.title.text) {
                vm.title.text = [vm.title.text.slice(0, $('#titleArea').prop("selectionStart")), "{{invoiceNumber}}",
                    vm.title.text.slice($('#titleArea').prop("selectionStart"))
                ].join('');
            } else {
                vm.title.text = "{{invoiceNumber}}";
            }
        }

        function addInvoiceDate() {
            if (vm.title.text) {
                vm.title.text = [vm.title.text.slice(0, $('#titleArea').prop("selectionStart")), "{{invoiceDate}}",
                    vm.title.text.slice($('#titleArea').prop("selectionStart"))
                ].join('');
            } else {
                vm.title.text = "{{invoiceDate}}";
            }
        }

        function addNettoValue() {
            if (vm.title.text) {
                vm.title.text = [vm.title.text.slice(0, $('#titleArea').prop("selectionStart")), "{{nettoValue}}",
                    vm.title.text.slice($('#titleArea').prop("selectionStart"))
                ].join('');
            } else {
                vm.title.text = "{{nettoValue}}";
            }
        }

        function checkTemplateTitle(size) {
            MailTemplatesService.checkUnique(vm.title.name, vm.title.activity, vm.title.language, 'EMAIL_TITLE').then(function(response) {
                vm.addTemplateTitle(vm.title.name, vm.title.activity, vm.title.text, vm.title.language);
            }).catch(function(response) {
                if (response.data.unique == false) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        ariaLabelledBy: 'modal-title',
                        ariaDescribedBy: 'modal-body',
                        templateUrl: 'app/views/system-settings/template-upload-modal/system-settings.modal.html',
                        controller: 'SystemSettingsModalController',
                        controllerAs: 'vm',
                        size: size
                    });

                    modalInstance.result.then(function(go) {
                        if (go) {
                            vm.addTemplateTitle(vm.title.name, vm.title.activity, vm.title.text, vm.title.language);
                        }
                    });
                } else {
                    vm.addingEmailTitleSuccessText = null;
                    vm.addingEmailTitleErrorText = $translate.instant('error.occurred');
                }
            });
        }


        function addTemplateTitle(name, activity, text, language) {
            MailTemplatesService.addTemplateTitle(name, activity, text, language).then(function(response) {
                vm.getTitleTemplates();
                vm.clearForms();
                vm.addingEmailTitleSuccessText = $translate.instant('systemSettings.messages.templateSuccess');
                vm.addingEmailTitleErrorText = null;
            }).catch(function(response) {
                vm.addingEmailTitleSuccessText = null;
                vm.addingEmailTitleErrorText = $translate.instant('error.occurred');
            });
        }

        function getClientByNumber() {
            ClientsService.findByClientNumber(vm.clientId).then(function(response) {
                vm.dhlClient = response.data;
                vm.getAllEmailTemplates();
            }).catch(function(response) {
                vm.dhlClient = null;
                if (response.status == 404) {
                    vm.clientErrorText = $translate.instant('systemSettings.messages.noClientFound');
                    vm.clientSuccessText = null;
                } else {
                    vm.clientErrorText = $translate.instant('error.occurred');
                    vm.clientSuccessText = null;
                }
            });

        }

        function getAllEmailTemplates() {
            MailTemplatesService.getAllTemplates().then(function(response) {
                if (response.data._embedded != null) {
                    vm.dhlEmailTempaltes = response.data._embedded.dhlTemplates;
                    vm.getUserTemplatesId();
                } else {
                    vm.clientErrorText = $translate.instant('systemSettings.messages.templatesDownloadFailed');
                    vm.clientSuccessText = null;
                }
            }).catch(function(response) {
                vm.clientErrorText = $translate.instant('systemSettings.messages.templatesDownloadFailed');
                vm.clientSuccessText = null;
            });
        }

        function getUserTemplatesId() {
            ClientsService.getClientTemplates(vm.dhlClient.ediClientId).then(function(response) {
                vm.dhlEmailTempaltesIds = response.data;


                var id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PAPER_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titles.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PAPER_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templates.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PAPER_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];
                
                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.titlesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PAPER_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];
                
                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                vm.client.templatesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];


            }).catch(function(response) {
                vm.clientErrorText = $translate.instant('systemSettings.messages.templatesDownloadFailed');
                vm.clientSuccessText = null;
            });
        }

        function getCommonElementFromLists(list1, list2) {
            var id;
            angular.forEach(list1, function(value, key) {
                if (list2.indexOf(value.ediTemplateId) > -1) {
                    id = value.ediTemplateId;
                }
            });
            return id;
        }

        //client + listofid
        function sendNewUserTemplates() {
            vm.templatesToSend = [];

            vm.templatesToSend.push(vm.client.titles.ACCOUNT_OPENED.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.EMAIL_ADDED.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.REGISTRATION_FAIL.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.PASSWORD_CHANGE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.PAPER_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.INVOICE_WITH_LINK.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.INVOICE_WITH_PDF.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titles.INVOICE_TOO_LARGE.ediTemplateId);

            vm.templatesToSend.push(vm.client.templates.ACCOUNT_OPENED.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.EMAIL_ADDED.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.REGISTRATION_FAIL.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.PASSWORD_CHANGE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.PAPER_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.INVOICE_WITH_LINK.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.INVOICE_WITH_PDF.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templates.INVOICE_TOO_LARGE.ediTemplateId);

            vm.templatesToSend.push(vm.client.titlesEN.ACCOUNT_OPENED.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.EMAIL_ADDED.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.REGISTRATION_FAIL.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.PASSWORD_CHANGE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.PAPER_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.INVOICE_WITH_LINK.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.INVOICE_WITH_PDF.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.titlesEN.INVOICE_TOO_LARGE.ediTemplateId);

            vm.templatesToSend.push(vm.client.templatesEN.ACCOUNT_OPENED.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.EMAIL_ADDED.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.REGISTRATION_FAIL.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.PASSWORD_CHANGE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.PAPER_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.INVOICE_WITH_LINK.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.INVOICE_WITH_PDF.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.CORRECTION_INVOICE.ediTemplateId);
            vm.templatesToSend.push(vm.client.templatesEN.INVOICE_TOO_LARGE.ediTemplateId);


            ClientsService.postClientTemplates(vm.dhlClient.ediClientId, vm.templatesToSend).then(function(response) {
                vm.clientErrorText = null;
                vm.clientSuccessText = $translate.instant('systemSettings.messages.templatesEditSuccess');
            }).catch(function(response) {
                vm.clientErrorText = $translate.instant('systemSettings.messages.templatesEditFail');
                vm.clientSuccessText = null;
            });
        }

        function changeActionTypeToText(actionType) {
            return filterFilter(vm.ActivityTypes, { name: actionType }, true)[0].text();
        }

        vm.listTemplates = {
            first: function() {
                UtilService.get(vm.dhlTemplates, 'first', null, null)
                    .then(vm.listTemplates.then);
            },
            previous: function() {
                UtilService.get(vm.dhlTemplates, 'prev', null, null)
                    .then(vm.listTemplates.then);
            },
            next: function() {
                UtilService.get(vm.dhlTemplates, 'next', null, null)
                    .then(vm.listTemplates.then);
            },
            last: function() {
                UtilService.get(vm.dhlTemplates, 'last', null, null)
                    .then(vm.listTemplates.then);
            },
            then: function(response) {
                vm.dhlTemplates = response.data;
            }
        };
        vm.listTitles = {
            first: function() {
                UtilService.get(vm.dhlTitles, 'first', null, null)
                    .then(vm.listTitles.then);
            },
            previous: function() {
                UtilService.get(vm.dhlTitles, 'prev', null, null)
                    .then(vm.listTitles.then);
            },
            next: function() {
                UtilService.get(vm.dhlTitles, 'next', null, null)
                    .then(vm.listTitles.then);
            },
            last: function() {
                UtilService.get(vm.dhlTitles, 'last', null, null)
                    .then(vm.listTitles.then);
            },
            then: function(response) {
                vm.dhlTitles = response.data;
            }
        };

        function setTooltip() {
            vm.htmlTooltip = true;
        }

        function disableTooltip() {
            vm.htmlTooltip = false;
        }

        function chooseOrDrop() {
            return $translate.instant('systemSettings.chooseOrDrop');
        }

        $scope.$watch(function() {
            var element = angular.element(document.querySelector('.html-input'));
            return element.attr('class');
        },
        function(newValue) {
            if (newValue && newValue.indexOf('ng-invalid-pattern') !== -1) {
                vm.htmlTooltip = true;
            } else if (!newValue || newValue.indexOf('ng-valid') !== -1) {
                vm.htmlTooltip = false;
            }
        });

        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(ngMaterialConfig);

    ngMaterialConfig.$inject = ['$mdThemingProvider', '$mdDateLocaleProvider'];

    function ngMaterialConfig($mdThemingProvider, $mdDateLocaleProvider) {
        $mdThemingProvider.theme('default')
            .primaryPalette('red')
            .accentPalette('red');

        $mdDateLocaleProvider.firstDayOfWeek = 1;
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('nip', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' id='dhlNip' name='dhlNip' ng-pattern='/^[1-9][0-9]{9}$/' class='form-control register-input normal-form'ng-model='vm.data.nip' uib-tooltip={{nipTooltip()}} tooltip-placement='top' required='required' ng-class='registerNip' />",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    //Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);
                    //Validate minimum
                    control.$validators.minimum = jQuery.proxy(function(value) {
                        var minimum = 10;
                        if (UtilService.isFilled($(this).attr('data-min')))
                            minimum = $(this).attr('data-min');
                        return (control.$isEmpty(value) || (value.length >= minimum));
                    }, element);
                    //Validate maximum
                    control.$validators.maximum = jQuery.proxy(function(value) {
                        var maximum = 10;
                        if (UtilService.isFilled($(this).attr('data-max')))
                            maximum = $(this).attr('data-max');
                        return (control.$isEmpty(value) || (value.length <= maximum));
                    }, element);
                    //Validate format
                    control.$validators.validDigits = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[1-9][0-9]{9}$/.test(value));
                    }, element);
                    control.$parsers.push(function(val) {
                        if (angular.isUndefined(val)) {
                            var val = '';
                        }
                        var clean = val.replace(/[^0-9]+/g, '');
                        if (val !== clean) {
                            control.$setViewValue(clean);
                            control.$render();
                        }
                        return clean;
                    });

                    $(element).bind('keypress', function(event) {
                        if (event.keyCode === 32) {
                            event.preventDefault();
                        }
                    });

                    scope.nipTooltip = function() {
                        var tooltipNip = '';
                        if (control.$error.maximum || control.$error.minimum || control.$error.validDigits)
                            tooltipNip = $translate.instant('directives.requiredNip');
                        else if (control.$error.required)
                            tooltipNip = $translate.instant('directives.requiredNip');

                        return tooltipNip;
                    }

                    $(element).on('keyup change', function(event) {
                        if ($("#dhlNip").hasClass("ng-invalid"))
                            $("#dhlNipLabel").addClass("red")
                        else
                            $("#dhlNipLabel").removeClass("red")
                    });
                }
            }
        }]);
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$scope', '$state', '$stateParams', '$translate', '$uibModal', 'UsersService', 'UtilService', 'AlertService'];

    function UsersController($scope, $state, $stateParams, $translate, $uibModal, UsersService, UtilService, AlertService) {
        var vm = this;
        vm.translate = $translate.instant;

        /*Declare variables*/
        vm.filter = {
            data: {
                size: 25,
                page: 0,
                email: "",
                clientNumber: "",
                clientName: ""
            },
            clear: function() {
                vm.filter.data.email = "";
                vm.filter.data.clientNumber = "";
                vm.filter.data.clientName = "";
            },
            save: function() {
                if (vm.data && vm.data.page)
                    vm.filter.data.page = vm.data.page.number;
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };

        vm.data = null;

        /*Declare functions*/
        //Controller init
        vm.init = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data))
                vm.list.current();
        };

        //Getting users
        vm.list = {
            //List users
            current: function() {
                vm.filter.data.page = 0;
                UsersService.listWithParams(vm.filter.data)
                    .then(vm.list.then);
            },
            //Go to the first page
            first: function() {
                UtilService.get(vm.data, 'first', null, null)
                    .then(vm.list.then);
            },
            //Go to the previous page
            previous: function() {
                UtilService.get(vm.data, 'prev', null, null)
                    .then(vm.list.then);
            },
            //Go to the next page
            next: function() {
                UtilService.get(vm.data, 'next', null, null)
                    .then(vm.list.then);
            },
            //Go to the last page
            last: function() {
                UtilService.get(vm.data, 'last', null, null)
                    .then(vm.list.then);
            },
            then: function(response) {
                //Set data to controller data
                vm.data = response.data;
                if (response.data._embedded == null) {
                    AlertService.error('users.users.noUsersFound');
                } else {
                    AlertService.clear();
                }
                //Write state to URL
                vm.filter.save();
            }
        };

        //Managing user
        vm.user = {
            form: function() {
                var newUserModal = $uibModal.open({
                    templateUrl: 'app/views/users/newUser/newUser.html',
                    controller: 'NewUserController',
                    controllerAs: 'vm',
                    size: 'md'
                });

                newUserModal.result.then(function(newUser) {
                    UsersService.create(newUser).then(function() {
                        vm.list.current();
                    });
                });
            }
        };

        //Navigating
        vm.nav = {
            user: function(user) {
                $state.go('user', user);
            }
        };

        //Generating report
        vm.report = {
            form: function() {
                var reportModal = $uibModal.open({
                    templateUrl: 'app/views/users/report/userReport.html',
                    controller: 'UserReportController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        filter: angular.copy(vm.filter.data)
                    }
                });

                reportModal.result.then(function(result) {});
            }
        };

        //Init controller
        vm.init();

        //Position fix-table
        var fixed = $('#fixed'),
            underFixed = $('#under-fixed');
        $scope.$watch(
            function() {
                return fixed.outerHeight();
            },
            function(newValue) {
                underFixed.css('padding-top', newValue);
            });
    }
})();

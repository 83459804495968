(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('UserController', UserController);

    UserController.$inject = ['$state', '$stateParams', '$translate', '$uibModal', 'UsersService', 'RolesService', 'UtilService', 'AlertService', 'Principal'];

    function UserController($state, $stateParams, $translate, $uibModal, UsersService, RolesService, UtilService, AlertService, Principal) {
        var vm = this;
        vm.translate = $translate.instant;

        /*Declare variables*/
        vm.filter = {
            data: {
                size: 10,
                page: 0,
                email: "",
                clientNumber: "",
                clientName: ""
            },
            clear: function() {
                vm.filter.data.email = "";
                vm.filter.data.clientNumber = "";
                vm.filter.data.clientName = "";
            },
            save: function() {
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };

        vm.data = {};
        vm.rolesChangeError = null;
        vm.rolesChangeSuccess = null;
        vm.deleteMessage = null;

        /*Declare functions*/
        //Controller init
        vm.init = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data)) {
                vm.user.get();
            } else
                $state.go('users');
        };

        //Managing user
        vm.user = {
            get: function() {
                UsersService.get(vm.filter.data.email)
                    //If successful
                    .then(vm.user.then)
                    //If there were errors
                    .catch(function(response) {})
                    .finally(function(response) {});
            },
            then: function(response) {
                //Set data to controller data
                vm.data.user = response.data;

                if(Principal.hasUserWrite()) {
                    vm.user.deleteMessage();
                }

                vm.user.getClients();
                vm.user.getUserRoles();
                vm.user.hasDocumentsAccess();
                vm.user.getUserDates();
                //vm.user.getFirstActivationDate();
                //vm.user.getLastActivityDate();
                vm.user.getLastFailedLoginDate();
                vm.user.getLastSuccessfulLoginDate();


                //Write state to URL
                vm.filter.save();
            },
            getClients: function() {
                UtilService.get /*his clients*/ (vm.data.user, 'clients', null, null)
                    .then(function(response) {
                        //Set clients to user
                        if(response.data != null && response.data._embedded != null) {
                            vm.data.user.clients = response.data._embedded.dhlClients;
                        }
                    });
            },
            getUserRoles: function() {
                if (Principal.hasSystemAccess()) {
                    vm.roles.getAll(); //all roles
                    UtilService.get /*his roles*/ (vm.data.user, 'roles', null, null)
                        .then(function(response) {
                            response.data._embedded.ediRoles.forEach(function(userRole) {
                                if (vm.data.roles) {
                                    vm.data.roles.forEach(function(role) {
                                        if (userRole.roleName === role.roleName)
                                            role.active = true;
                                    });
                                }
                            });
                        });
                }
            },
            getUserDates: function() {
                UsersService.getUserDates(vm.data.user.ediUserId)
                    .then(function(response) {
                        vm.data.user.firstActivationDate = response.data.firstActivationDate;
                        vm.data.user.lastActivityDate = response.data.lastActivity;
                    });
            },
            getFirstActivationDate: function() {
                UsersService.getFirstActivationDate(vm.data.user.ediUserId)
                    .then(function(response) {
                        vm.data.user.firstActivationDate = response.data;
                    });
            },
            getLastActivityDate: function() {
                UsersService.getLastActivityDate(vm.data.user.ediUserId)
                    .then(function(response) {
                        vm.data.user.lastActivityDate = response.data;
                    });
            },
            getLastFailedLoginDate: function() {
                UsersService.getLastFailedLoginDate(vm.data.user.ediUserId)
                    .then(function(response) {
                        vm.data.user.lastFailedLoginDate = response.data;
                    });
            },
            getLastSuccessfulLoginDate: function() {
                UsersService.getLastSuccessfulLoginDate(vm.data.user.ediUserId)
                    .then(function(response) {
                        vm.data.user.lastSuccessfulLoginDate = response.data;
                    });
            },
            clients: function() {
                var reportModal = $uibModal.open({
                    templateUrl: 'app/views/users/user/clients/userClients.html',
                    controller: 'UserClientsController',
                    controllerAs: 'vm',
                    size: 'lg',
                    resolve: {
                        filter: {
                            email: vm.data.user.email,
                            ediUserId: vm.data.user.ediUserId
                        }
                    }
                });

                reportModal.result.then(function(result) {
                    AlertService.clear();
                    result.response
                        .then(function(response) {
                            var alertData = {
                                email: response.config.params.email,
                                clientNumber: response.config.params.clientNumber
                            };
                            if (result.type === 'add') {
                                AlertService.success("users.user.clients.successfullyAssigned", alertData);
                            } else if (result.type === 'removeOne'){
                                AlertService.success("users.user.clients.successfullyRemoved", alertData);
                            } else {
                                AlertService.success("users.user.clients.successfullyRemovedAll", {clientNumber: result.clientNumber});
                            }
                            vm.user.get();
                        })
                        .catch(function(response) {
                            if (result.type === 'add' && response.status === 409) {
                                AlertService.warning("users.user.clients.alreadyAssigned", {
                                    email: response.config.params.email,
                                    clientNumber: response.config.params.clientNumber
                                });
                            } else {
                                AlertService.error("users.errors.occurred");
                            }
                        });
                });
            },

            unlock: function() {
                UsersService.unlock(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.userUnlocked");
                        vm.data.user.locked = false;
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },
            lock: function() {
                UsersService.lock(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.userLocked");
                        vm.data.user.locked = true;
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },

            unlockDocuments: function() {
                UsersService.unlockDocuments(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.receivables.documentsUnlockSuccess");
                        vm.data.user.documentsLocked = false;
                    })
                    .catch(function(response) {
                        AlertService.error("users.user.receivables.documentsUnlockFail");
                    });
            },

            lockDocuments: function() {
                UsersService.lockDocuments(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.receivables.documentsLockSuccess");
                        vm.data.user.documentsLocked = true;
                    })
                    .catch(function(response) {
                        AlertService.error("users.user.receivables.documentsLockFail");
                    });
            },

            resetPassword: function() {
                UsersService.resetPassword(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.passResetSent");
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },
            toggleActivation: function() {
                if (vm.data.user.activated)
                    vm.user.deactivate();
                else
                    vm.user.activate();
            },
            activate: function() {
                UsersService.activate(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.userActivated");
                        vm.data.user.activated = true;
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },
            deactivate: function() {
                UsersService.deactivate(vm.data.user.ediUserId)
                    .then(function(response) {
                        AlertService.success("users.user.userDeactivated");
                        vm.data.user.activated = false;
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },
            delete: function() {
                UsersService.delete(vm.data.user.ediUserId)
                    .then(function(response) {
                        $state.go("users");
                        setTimeout(function() {
                            AlertService.success("users.user.userDeleted", {email: vm.data.user.email});
                        }, 100);
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                    });
            },
            deleteMessage: function() {
                UsersService.hasReceivablesClients(vm.data.user.ediUserId)
                    .then(function(response) {
                        if(response.data.message === true) {
                            vm.deleteMessage = $translate.instant("users.user.hasReceivablesClients");
                        } else {
                            vm.deleteMessage = $translate.instant("confirm.areYouSure");
                        }
                    })
                    .catch(function(response) {
                        AlertService.error("users.errors.occurred");
                        vm.deleteMessage = $translate.instant("users.user.mayHaveReceivablesClients");
                    });
            },
            //not used anymore
            // isLocked: function() {
            //     return UsersService.isLocked(vm.data.user.ediUserId)
            //         .then(function(response) {
            //             vm.data.user.locked = response.data;
            //             return response.data;
            //         });
            // },
            hasDocumentsAccess: function() {
                UsersService.hasDocumentsAccess(vm.data.user.ediUserId)
                    .then(function(response) {
                        if(response.data.message === true)
                            vm.data.user.documentsLocked = false;
                        else 
                            vm.data.user.documentsLocked = true;
                    });
            }
        };

        vm.roles = {
            getAll: function() {
                RolesService.list()
                    .then(function(response) {
                        vm.data.roles = response.data._embedded.ediRoles;
                    });
            },
            change: function() {
                var roles = [];
                vm.data.roles.forEach(function(role) {
                    if (role.active)
                        roles.push(role.roleName);
                });
                UsersService.changeRoles(vm.data.user.ediUserId, roles)
                    .then(function(response) {
                        vm.rolesChangeError = null;
                        vm.rolesChangeSuccess = true;
                    })
                    .catch(function(response) {
                        vm.rolesChangeError = true;
                        vm.rolesChangeSuccess = null;
                    });
            }
        };

        //Navigating
        vm.nav = {
            users: function() {
                $state.go('users');
            },
            client: function(client) {
                $state.go('client', client);
            }
        };

        //Init controller
        vm.init();
    }
})();

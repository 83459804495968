(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PasswordExpiredController', PasswordExpiredController);

    PasswordExpiredController.$inject = ['$uibModalInstance', 'param'];

    function PasswordExpiredController($uibModalInstance, param) {
        var vm = this;

        vm.data = {
            days: param
        };

        vm.modal = {
            ok: function() {
                $uibModalInstance.close();
            }
        };
    }
})();

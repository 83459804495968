(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('pemail', [function() {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' id='dhlEmail' name='dhlEmail' class='form-control register-input normal-form' "+
                "ng-model='vm.data.email' autocomplete='off' tooltip-placement='top' ng-class='registerEmail' "+
                "uib-tooltip={{dynamicTooltip}} tooltip-placement='top'/>",
                link: function(scope, element, attributes, control) {
                    // Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);

                    // Validate email
                    control.$validators.email = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value));
                    }, element);
                }
            }
        }]);
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('NewUserController', NewUserController);

    NewUserController.$inject = ['$uibModalInstance', '$translate', 'AlertService', 'UsersService'];

    function NewUserController($uibModalInstance, $translate, AlertService, UsersService) {
        var vm = this;

        vm.data = {
            email: null,
            password: null
        };

        vm.modal = {
            add: function() {
                UsersService.isEmailUnique(vm.data.email)
                    .then(function(response){
                        UsersService.checkNewUsersPasswordValid(vm.data.password)
                            .then(function(response){
                                $uibModalInstance.close(vm.data);
                            }).catch(function(response){
                                var passwordChangeError;
                                switch (response.data.message) {
                                    case 1:
                                        passwordChangeError = "users.newUser.error1";
                                        break;
                                    case 4:
                                        passwordChangeError = "users.newUser.error4";
                                        break;
                                    case 5:
                                        passwordChangeError = "users.newUser.error5";
                                        break;
                                    default:
                                        passwordChangeError = "error.occurred";
                                }
                                AlertService.error(passwordChangeError);
                            });
                    }).catch(function(response){
                        AlertService.error("users.newUser.exists");
                    });
            },
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };
    }
})();

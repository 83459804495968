(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('Account', Account);

    Account.$inject = ['API_URL', '$resource'];

    function Account(API_URL, $resource) {
        var service = $resource(API_URL + 'api/session', {}, {
            'get': {
                method: 'GET',
                params: {},
                isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            }
        });

        return service;
    }
})();

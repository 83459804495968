(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('MailTemplatesService', MailTemplatesService);

    MailTemplatesService.$inject = ['API_URL', '$http'];

    function MailTemplatesService(API_URL, $http) {
        var service = {
            checkUnique: checkUnique,
            getTemplatesByType: getTemplatesByType,
            download: download,
            addTemplateTitle: addTemplateTitle,
            getAllTemplates: getAllTemplates
        };

        return service;

        function checkUnique(name, actionType, language, type) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlTemplates/isTemplateUnique',
                params: { 'name': name, 'actionType': actionType, 'language': language, 'type': type }
            });
        }

        function getTemplatesByType(type) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlTemplates/search/findByType',
                params: { 'type': type, 'size': 10 }
            });
        }

        function download(name, actionType, language) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlTemplates/download',
                params: { 'name': name, 'actionType': actionType, 'language': language },
                responseType: 'arraybuffer'
            });
        }

        function addTemplateTitle(name, actionType, text, language) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlTemplates/add',
                params: { 'name': name, 'actionType': actionType, 'text': text, 'language': language, 'type': 'EMAIL_TITLE' }
            });
        }

        function getAllTemplates() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlTemplates/search/findAllBy'
            });
        }

    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .filter('capitalize', capitalize);

    function capitalize() {
        return capitalizeFilter;

        function capitalizeFilter (input) {

            if (typeof value === "undefined" || value === undefined || value === null) {
                return "";
            }
            input = input.toLowerCase();
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        }
    }
})();

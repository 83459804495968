(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .service('HttpPendingRequestsService', HttpPendingRequestsService)
        .factory('serviceUnavailableInterceptor', serviceUnavailableInterceptor);

    serviceUnavailableInterceptor.$inject = ['$window', '$q', 'HttpPendingRequestsService'];

    function serviceUnavailableInterceptor ($window, $q, HttpPendingRequestsService) {
        var service = {
            request: handleRequest,
            responseError: handleResponseError
        };

        return service;

        function handleResponseError(response) {
            if (response.status === 404 && angular.isDefined(response.data) && response.data.rest_not_available === true) {
                HttpPendingRequestsService.cancelAll();
                $window.location.href = $window.location.origin + "/40x.htm";
                throw new Error("Backend is not available! Redirecting to mainenance page.");
            }

            if (response.config.timeout.isGloballyCancelled) {
                return $q.defer().promise;
            }
            return $q.reject(response);
        }

        function handleRequest(config) {
            config = config || {};
            if (config.timeout === undefined && !config.noCancelOnRouteChange) {
                config.timeout = HttpPendingRequestsService.newTimeout();
            }
            return config;
        }
    }

    HttpPendingRequestsService.$inject = ['$q'];

    function HttpPendingRequestsService($q) {
        var cancelPromises = [];

        function newTimeout() {
            var cancelPromise = $q.defer();
            cancelPromises.push(cancelPromise);
            return cancelPromise.promise;
        }

        function cancelAll() {
            angular.forEach(cancelPromises, function(cancelPromise) {
                cancelPromise.promise.isGloballyCancelled = true;
                cancelPromise.resolve();
            });
            cancelPromises.length = 0;
        }

        return {
            newTimeout: newTimeout,
            cancelAll: cancelAll
        };
    }
})();

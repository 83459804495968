(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('UserClientsController', UserClientsController);

    UserClientsController.$inject = ['$uibModalInstance', '$translate', 'filter', 'UsersService', 'ClientsService'];

    function UserClientsController($uibModalInstance, $translate, filter, UsersService, ClientsService) {
        var vm = this;
        vm.translate = $translate.instant;

        vm.data = {
            userId: filter.ediUserId,
            email: filter.email,
            clientNumber: "",
            clientName: null
        };

        vm.clients = {
            timeout: null,
            lookup: function() {
                clearTimeout(vm.clients.timeout);
                vm.clients.timeout = setTimeout(function() {
                    vm.data.clientName = null;
                    ClientsService.findByClientNumber(vm.data.clientNumber)
                        .then(function(response) {
                            vm.data.clientName = response.data.clientName;
                        })
                        .catch(function(response) {
                            vm.data.clientName = null;
                        });
                }, 500);
            },
            addClient: function() {
                var result = {
                    response: UsersService.addClient(vm.data.userId, vm.data.email, vm.data.clientNumber),
                    type:'add'
                };
                $uibModalInstance.close(result);
            },
            removeClient: function() {
                var result = {
                    response: UsersService.removeClient(vm.data.userId, vm.data.email, vm.data.clientNumber),
                    type:'removeOne'
                };
                $uibModalInstance.close(result);
            },
            removeAllClients: function() {
                var result = {
                    response: UsersService.removeAllClients(vm.data.userId, vm.data.email),
                    type:'removeAll',
                    clientNumber: vm.data.clientNumber
                };
                $uibModalInstance.close(result);
            }
        };

        vm.modal = {
            cancel: function() {
                $uibModalInstance.dismiss('cancel');
            }
        };
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('register_end', {
            parent: 'application',
            url: '/register_end',
            data: {
                pageTitle: 'global.menu.register_end'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/register/register_end.html',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('register');
                    return $translate.refresh();
                }]
            }
        });
    }
})();

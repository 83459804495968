(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ReceivablesController', ReceivablesController);

    ReceivablesController.$inject = ['$window', '$rootScope', '$scope', 'Principal', '$timeout', 'ReceivablesService', 'AlertService'];

    function ReceivablesController($window, $rootScope, $scope, Principal, $timeout, ReceivablesService, AlertService) {
        var vm = this;
        
        vm.getXlsxURL = getXlsxURL;
        vm.sortType = 'dateIssue';
        vm.sortReverse = true;
        vm.sort = '';

        //Declare functions exposed to view (html)
        vm.init = init;
        vm.list = list;
        vm.sortBy = sortBy;
        vm.switchHidePaid = switchHidePaid;
        vm.checkboxClicked = checkboxClicked;
        vm.redirectComplaint = redirectComplaint;
        vm.redirectDuplicate = redirectDuplicate;
        vm.closeBlockedPayersAlert = closeBlockedPayersAlert;
        vm.closeDuplicateAlert = closeDuplicateAlert;
        vm.closeComplaintAlert = closeComplaintAlert;

        //variables
        vm.selectedIndex = null;
        vm.hidePaid = true;
        vm.duplicateDisabled = null;
        vm.complaintDisabled = null;
        vm.allPaid = false;
        vm.noDocumentsFound = null;
        vm.duplicateAlertMsg = null;
        vm.complaintAlertMsg = null;
        vm.receivablesStatusDate = null;
        vm.blockedPayersList = null;
        vm.blockedPayersListMsg = null;
        vm.blockedPayersMsg = null;
        vm.invoices = [];
        vm.debtContacts = [];
        vm.showDebtContacts = false;

        vm.Principal = Principal;
        vm.window = $(window);
        vm.table = $("#fixtable[fix-table]");
        vm.topSpacer = $('#topSpacer').find("td");
        vm.bottomSpacer = $('#bottomSpacer').find("td");
        vm.topIndex = 0;
        vm.height = 31;
        vm.limit = Math.max(Math.ceil(vm.window.height() / vm.height) + 10, 50);

        vm.window.resize(function() {
            vm.height = vm.table.find("td").eq(2).outerHeight();
            vm.limit = Math.ceil(vm.window.height() / vm.height) + 10;
            vm.window.trigger("scroll");
        });

        var windowScrollTimeout;
        vm.window.scroll(function() {
            $timeout.cancel(windowScrollTimeout);

            windowScrollTimeout = $timeout(function() {
                var numberOfRows = Math.floor((vm.window.scrollTop() - vm.table.offset().top) / vm.height);
                vm.topIndex = Math.max(0, numberOfRows % 2 !== 0 ? numberOfRows + 1 : numberOfRows);
            }, 20);
        });

        $scope.$watchGroup(["vm.topIndex", "vm.height"], resizePlaceholders);

        $scope.$watchCollection("vm.invoices", resizePlaceholders);

        function resizePlaceholders() {
            vm.topSpacer.height(vm.topIndex * vm.height);
            vm.bottomSpacer.height(vm.invoices.length < vm.limit ? 0 : (vm.invoices.length - vm.limit - vm.topIndex) * vm.height);
        }

        vm.filter = {
            clientNo: null,
            hidePaid: vm.hidePaid
        };

        vm.columns = {
            clientNumber: true,
            clientName: true,
            documentNumber: true,
            dateIssue: true,
            dueDate: true,
            totalGross: true,
            left: true,
            currency: true,
            remarks: true,
            eInvoice: true
        };

        vm.columns.totalVisible = 10;

        //table header layout sometimes breaks, i.e. after alert messages shown/closed
        //workaround: refresh it by simulating page scroll
        function refreshTable() {
            var y = $(window).scrollTop();  //current position on the page
            $(window).scrollTop(y+1);   
            $(window).scrollTop(y-1);
        }

        function getInvoiceIndex(entityId) {
            var i;
            for (i = 0; i < vm.invoices.length; ++i) {
                if (vm.invoices[i].entityId === entityId)
                    return i;
            }
        }

        function checkboxClicked(event, entityId) {
            event.stopPropagation();
            if (event.target.checked) {
                if(vm.selectedIndex !== null) {
                    vm.invoices[vm.selectedIndex].selected = false;
                }
                vm.selectedIndex = getInvoiceIndex(entityId);
            } else {
                vm.selectedIndex = null;
            }
            complaintBtnCheck();
            duplicateBtnCheck();
            closeDuplicateAlert();
            closeComplaintAlert();
        }

        function duplicateBtnCheck() {
            if(vm.selectedIndex != null) {
                if(vm.invoices[vm.selectedIndex].eInvoice === false) {
                    var documentNumber = vm.invoices[vm.selectedIndex].docNumber;
                    if(documentNumber.length === 10) {
                        var prefix = documentNumber.substr(0, 2);
                        if(prefix === '11' || prefix === '21') {
                            vm.duplicateDisabled = false;
                            return;
                        }
                    }
                } else {
                    vm.duplicateDisabled = 'receivables.messages.duplicateErrorE'; //message for e-invoice
                    return;
                }
            }
            vm.duplicateDisabled = 'receivables.messages.duplicateError'; //message for paper invoice
        }

        function complaintBtnCheck() {
            if(vm.selectedIndex != null) {
                var documentNumber = vm.invoices[vm.selectedIndex].docNumber;
                var prefix = documentNumber.substr(0, 2);
                if(prefix === '11' || prefix === '21') {
                    vm.complaintDisabled = false;
                    return;
                }
            }
            vm.complaintDisabled = 'receivables.messages.complaintError';
        }

        function closeComplaintAlert() {
            vm.complaintAlertMsg = null;
            refreshTable();
        }

        function redirectComplaint() {
            if(vm.complaintDisabled) {
                if(vm.complaintAlertMsg === null) {
                    vm.complaintAlertMsg = vm.complaintDisabled;
                    refreshTable();
                }
            } else {
                closeComplaintAlert();
                $window.open("https://ecom.dhl.com.pl/reklamacjefaktur/", "_blank");
            }
        }

        function closeDuplicateAlert() {
            vm.duplicateAlertMsg = null;
            refreshTable();
        }

        function redirectDuplicate() {
            if(vm.duplicateDisabled) {
                if(vm.duplicateAlertMsg === null) {
                    vm.duplicateAlertMsg = vm.duplicateDisabled;
                    refreshTable();
                }
            } else {
                closeDuplicateAlert();
                $window.open("https://ecom.dhl.com.pl/duplikatyfaktur/", "_blank");
            }
        }

        function getBlockedPayers() {
            ReceivablesService.getBlockedPayers(Principal.getProperty('userId'))
                .then(function(response) {
                    if (response.data != null) {
                        if(response.data.length > 0) {
                            vm.blockedPayersList = response.data;
                            showBlockedPayersAlert();
                        } else {
                            vm.blockedPayersList = [];
                        }
                    }
                });
        }
        
        function payersBlockedAlert() { //checks whether alert about blocked payers should be shown
            if(!$rootScope.blockedPayersAlertClosed) {  //if user has not closed alert manually yet
                if(vm.blockedPayersList === null) { //if blocked payers have not been fetched from server yet 
                    getBlockedPayers();
                } else if (vm.blockedPayersList.length > 0) { //if there are some blocked payers show alert
                    showBlockedPayersAlert();
                }
            }
        }

        function showBlockedPayersAlert() {
            vm.blockedPayersMsg = 'receivables.messages.payersBlockedBox';
            vm.blockedPayersListMsg = vm.blockedPayersList.join(", ") + ")";
            refreshTable();
        }

        function closeBlockedPayersAlert() {
            $rootScope.blockedPayersAlertClosed = true;
            vm.blockedPayersMsg = null;
            refreshTable();
        }

        function getDebtContacts() {
            ReceivablesService.getDebtContacts(Principal.getProperty('userId'))
                .then(function(response) {
                    if (response.data != null) {
                        for (var clientNumber in response.data) {
                            vm.debtContacts.push({clientNumber: clientNumber, email: response.data[clientNumber]});
                        }
                    }
                });
        }

        function switchHidePaid() {
            vm.hidePaid = !vm.hidePaid;
            vm.filter.hidePaid = vm.hidePaid;
            if(vm.selectedIndex !== null) {
                vm.invoices[vm.selectedIndex].selected = false;
                vm.selectedIndex = null;
            }
            list(vm.filter);
        }
        
        function checkAllPaid() {
            for (var i = 0; i < vm.invoices.length; i++) {
                if(vm.invoices[i].left !== 0.0) {
                    vm.allPaid = false;
                    return;
                }
            }
            vm.allPaid = true;
        }

        
        function markLate(receivables, currentDate) {
            angular.forEach(receivables, function(receivable) {
                var datePayment = new Date(receivable.datePayment);
                if(receivable.left !== 0 && datePayment < currentDate) {
                    receivable.late = true;
                } else {
                    receivable.late = false;
                }
            });
            return receivables;
        }
        
        function list(filter) {
            vm.selectedIndex = null; //reset selected document (checkbox column)
            closeDuplicateAlert();
            closeComplaintAlert();

            var listFilter = angular.copy(filter);
            listFilter.sortBy = String(vm.sortType);
            listFilter.reverse = vm.sortReverse;
            // listFilter.columnsFromJson = null;
            ReceivablesService.list(listFilter)
                .then(function(response) {
                    if (response.data._embedded != null && response.data._embedded.dhlReceivables != null) {
                        vm.invoices = markLate(response.data._embedded.dhlReceivables, new Date());
                        vm.noDocumentsFound = false;
                    } else {
                        vm.invoices = [];
                        vm.noDocumentsFound = true;
                    }
                    checkAllPaid();
                    getReceivablesStatusDate();
                })
                .catch(function(response) {
                    vm.invoices = [];
                    vm.noDocumentsFound = true;
                    vm.receivablesStatusDate = null;
                    AlertService.error('receivables.search.error');
                });
        }

        function getReceivablesStatusDate() {
            ReceivablesService.getReceivablesStatusDate()
                .then(function(response) {
                    if (response.data != null) {
                        vm.receivablesStatusDate = response.data.date;
                    } else {
                        vm.receivablesStatusDate = "No data";
                    }
                })
                .catch(function(response) {
                    vm.receivablesStatusDate = "No data";
                })
                .finally(function() {
                    refreshTable();
                });
        }

        function sortBy(column, reverse){
            vm.sortType = column;
            vm.sortReverse = reverse;
            vm.list(vm.filter);
        }

        function getXlsxURL() {
            var listFilter = angular.copy(vm.filter);
            listFilter.sortBy = String(vm.sortType);
            listFilter.reverse = vm.sortReverse;
            listFilter.columnsFromJson = vm.columns;
            return ReceivablesService.getXlsxURL(listFilter);
        }

        function init() {
            list(vm.filter);
            if(!Principal.hasAdminReceivablesAccess()) {
                payersBlockedAlert();
            }
            if(Principal.hasUserReceivablesAccess()) {
                getDebtContacts();
                vm.showDebtContacts = true;
            }
            ReceivablesService.logReceivablesViewed();
        }

        //Init controller
        vm.init();
    }
})();

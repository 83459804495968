(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('RejectionController', RejectionController);

    RejectionController.$inject = ['$uibModalInstance'];

    function RejectionController($uibModalInstance) {
        var vm = this;

        vm.reason = null;

        vm.modal = {
            go: function(reason) {
                var tmp = {
                    'action': true,
                    'text': reason
                };
                $uibModalInstance.close(tmp);
            },
            cancel: function() {
                var tmp = {
                    'action': false,
                    'text': null
                };
                $uibModalInstance.close(tmp);
            }
        };
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive("confirm", ['$document', '$parse','$translate','$translatePartialLoader', function($document, $parse,$translate,$translatePartialLoader) {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    var buttonId, html, message, nope, title, yep;
                    $translatePartialLoader.addPart('global');
                    $translate.refresh();
                    buttonId = Math.floor(Math.random() * 10000000000);

                    attrs.buttonId = buttonId;

                    message = attrs.confirmMessage || $translate.instant("confirm.areYouSure");
                    yep = attrs.yes || $translate.instant("confirm.yes");
                    nope = attrs.no || $translate.instant("confirm.no");
                    title = attrs.title || $translate.instant("confirm.confirm");

                    attrs.$observe('confirmMessage', function(value) {
                        message = attrs.confirmMessage || $translate.instant("confirm.areYouSure");
                        html = "<div id=\"button-" + buttonId + "\" class=\"confirm\" style=\"text-align: center\"><span class=\"confirmbutton-msg\">" + message + "</span><br><div class=\"confirmbutton-yes btn btn-primary btn-fill btn-round\" style=\"min-width: 40px\">" + yep + "</div><div class=\"confirmbutton-no btn btn-primary btn-round\" style=\"min-width: 40px\">" + nope + "</div></div>";
                    });

                    return element.bind('click', function(e) {
                        var dontBubble, pop;
                        dontBubble = true;

                        e.stopPropagation();

                        html = "<div id=\"button-" + buttonId + "\" class=\"confirm\" style=\"text-align: center\"><span class=\"confirmbutton-msg\">" + message + "</span><br><div class=\"confirmbutton-yes btn btn-primary btn-fill btn-round\" style=\"min-width: 40px\">" + yep + "</div><div class=\"confirmbutton-no btn btn-primary btn-round\" style=\"min-width: 40px\">" + nope + "</div></div>";

                        element.popover({
                            content: html,
                            html: true,
                            trigger: "manual",
                            title: title,
                            container: 'body', 
                            placement: 'top auto'
                        });

                        element.popover('show');

                        pop = $("#button-" + buttonId);

                        pop.closest(".popover").click(function(e) {
                            if (dontBubble) {
                                e.stopPropagation();
                            }
                        });

                        pop.find('.confirmbutton-yes').click(function(e) {
                            dontBubble = false;

                            var func = $parse(attrs.confirm);
                            func(scope);
                        });

                        pop.find('.confirmbutton-no').click(function(e) {
                            dontBubble = false;

                            $document.off('click.confirmbutton.' + buttonId);

                            element.popover('hide');
                        });

                        $document.on('click.confirmbutton.' + buttonId, ":not(.popover, .popover *)", function() {
                            $document.off('click.confirmbutton.' + buttonId);
                            element.popover('hide');
                        });
                    });
                }
            };
        }]);
})();

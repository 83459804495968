(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('principalProperty', ['Principal', function(Principal) {

			return {
				restrict: "A",
				link: function(scope, element, attrs) {
					var property  = attrs.principalProperty;
					element.text(Principal.getProperty(property));
				}
			}
		}]);
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$rootScope', '$state', '$translate', 'Auth', 'UsersService'];

    function PasswordResetController($rootScope, $state, $translate, Auth, UsersService) {
        var vm = this;
        vm.data = {
            email: null,
            passwordResetError: null,
            passwordResetSuccess: null
        };

        //Declare functions
        vm.init = init;
        vm.resetPassword = resetPassword;

        function resetPassword(){
            UsersService.requestResetOwnPassword(vm.data.email).then(function(response) {
                vm.data.passwordResetError = null;
                vm.data.passwordResetSuccess = $translate.instant('password-reset.after-reset');
            }).catch(function(response){
                vm.data.passwordResetError = $translate.instant('password-reset.error');
                vm.data.passwordResetSuccess = null;
            });
        }

        //Init functions
        function init() {
        }

        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PendingCasesController', PendingCasesController);

    PendingCasesController.$inject = ['PendingCasesService', 'AlertService', '$uibModal', 'UtilService', 'DocumentsService', '$translate'];

    function PendingCasesController(PendingCasesService, AlertService, $uibModal, UtilService, DocumentsService, $translate) {
        var vm = this;

        //Declare variables
        vm.usersToBeUpdated = [];
        vm.documentsToBeUpdated = [];
        vm.correctionsToBeUpdated = [];
        vm.users = [];
        vm.corrections = [];
        vm.documents = [];
        vm.checkAllUsersButton = true;
        vm.checkAllCorrectionsButton = true;
        vm.checkAllDocumentsButton = true;

        //Declare functions
        vm.init = init;
        vm.checkAllCorrections = checkAllCorrections;
        vm.checkAllDocuments = checkAllDocuments;
        vm.checkAllUsers = checkAllUsers;
        vm.denyCorrections = denyCorrections;
        vm.acceptCorrections = acceptCorrections;
        vm.denyDocuments = denyDocuments;
        vm.acceptDocuments = acceptDocuments;
        vm.denyUsers = denyUsers;
        vm.acceptUsers = acceptUsers;
        vm.usersCheckboxClick = usersCheckboxClick;
        vm.correctionsCheckboxClick = correctionsCheckboxClick;
        vm.documentsCheckboxClick = documentsCheckboxClick;
        vm.checkOrUncheckText = checkOrUncheckText;
        vm.getNameBySapNumber = getNameBySapNumber;
        vm.downloadPDF = downloadPDF;

        function checkOrUncheckText(condition) {
            return condition ? "pendingCases.pendingCases.selectAll" : "pendingCases.pendingCases.clearAll";
        }

        // add or remove user form usersToBeUpdated
        function usersCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.usersToBeUpdated.push(index);
            } else {
                var i;
                for (i = 0; i < vm.usersToBeUpdated.length; ++i) {
                    if (vm.usersToBeUpdated[i] === index) {
                        vm.usersToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        // add or remove correction form correctionsToBeUpdated
        function correctionsCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.correctionsToBeUpdated.push(vm.corrections[index].transactionId);
            } else {
                var i;
                for (i = 0; i < vm.correctionsToBeUpdated.length; ++i) {
                    if (vm.correctionsToBeUpdated[i] === vm.corrections[index].transactionId) {
                        vm.correctionsToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        // add or remove document form documentsToBeUpdated
        function documentsCheckboxClick(event, index) {
            event.stopPropagation();
            if (event.target.checked) {
                vm.documentsToBeUpdated.push(vm.documents[index].transactionId);
            } else {
                var i;
                for (i = 0; i < vm.documentsToBeUpdated.length; ++i) {
                    if (vm.documentsToBeUpdated[i] === vm.documents[index].transactionId) {
                        vm.documentsToBeUpdated.splice(i, 1);
                    }
                }
            }
        }

        function acceptUsers() {
            if (vm.usersToBeUpdated.length !== 0) { //if there is at least one selected user
                var usersList = [];

                var proceed = true;
                angular.forEach(vm.usersToBeUpdated, function(index) { //vm.users array indexes stored in vm.usersToBeUpdated
                    var user = vm.users[index];
                    if (!UtilService.isFilled(user.clientName) || !UtilService.isFilled(user.sapNumber)) {
                        AlertService.error("pendingCases.pendingCases.someInputEmpty");
                        proceed = false; //do not proceed with accepting, client data input is missing
                    } else {
                        usersList.push({
                            'userId': user.userId,
                            'ipAddress': user.ipAddress,
                            'email': user.email,
                            'nip': user.nip,
                            'invoiceNumber': user.invoiceNumber,
                            'invoiceValue': user.invoiceValue,
                            'sapNumber': user.sapNumber,
                            'clientName': user.clientName,
                            'payerNumber': user.payerNumber
                        });
                    }
                });

                if(proceed) {
                    PendingCasesService.usersAccepted(usersList)
                        .then(function(response) {
                            if (response.data.failed != null && Object.keys(response.data.failed).length > 0) {
                                var existingUsers = "";
                                var otherMsg = "";
                                for (var key in response.data.failed) {
                                    if (response.data.failed.hasOwnProperty(key)) {
                                        var message = response.data.failed[key].message;
                                        if (message == "pending.user.exist") {
                                            existingUsers += " " + response.data.failed[key].atrList[0];
                                        } else {
                                            otherMsg += "\n" + message;
                                        }
                                    }
                                }
                                var errors = "";
                                if (existingUsers !== "") {
                                    /*todo refactor to use dynamic translate (without $translate.instant)*/
                                    errors = $translate.instant("pendingCases.pendingCases.userExists") + existingUsers;
                                }
                                if (otherMsg !== "") {
                                    errors += otherMsg;
                                }

                                if (errors !== "") {
                                    /*todo msg function*/ AlertService.error(errors);
                                } else {
                                    AlertService.error("pendingCases.pendingCases.failedAcceptSomeUsers");
                                }
                            } else {
                                AlertService.success("pendingCases.pendingCases.successAcceptMarkedUsers");
                            }
                        })
                        .catch(function(response) {
                            AlertService.error("pendingCases.pendingCases.failedAcceptMarkedUsers");
                        })
                        .finally(function(response) {
                            getUsers();
                        });
                }
            }
        }

        function denyUsers(size) {
            if (vm.usersToBeUpdated.length !== 0) { //if there is at least one selected user
                var modalInstance = $uibModal.open({
                    animation: true,
                    ariaLabelledBy: 'modal-title',
                    ariaDescribedBy: 'modal-body',
                    templateUrl: 'app/views/pending-cases/rejection-modal/rejection.modal.html',
                    controller: 'RejectionController',
                    controllerAs: 'vm',
                    size: size
                });

                modalInstance.result.then(function(go) {
                    if (!go.text || /^\s*$/.test(go.text)) {
                        AlertService.error("pendingCases.pendingCases.noRejectionReason");
                        return; //do not proceed with denial if no rejection reason was provided
                    }

                    if (go.action) {
                        var usersList = [];

                        angular.forEach(vm.usersToBeUpdated, function(index) { //vm.users array indexes stored in vm.usersToBeUpdated
                            usersList.push({
                                'userId': vm.users[index].userId,
                                'rejectReason': go.text
                            });
                        });

                        PendingCasesService.usersDenied(usersList)
                            .then(function(response) {
                                if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                                    AlertService.success("pendingCases.pendingCases.successDeniedMarkedUsers");
                                } else {
                                    AlertService.error("pendingCases.pendingCases.failedDenySomeUsers");
                                }
                            })
                            .catch(function(response) {
                                AlertService.error("pendingCases.pendingCases.failedDenyMarkedUsers");
                            })
                            .finally(function(response) {
                                getUsers();
                            });
                    }
                });
            }
        }

        function acceptCorrections() {
            if(vm.correctionsToBeUpdated.length !== 0) {
                PendingCasesService.correctionsAccepted(vm.correctionsToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                            AlertService.success("pendingCases.pendingCases.acceptedCorrections");
                        } else {
                            AlertService.error("pendingCases.pendingCases.failedAcceptingSomeCorrections");
                        }
                    })
                    .catch(function(response) {
                        AlertService.error("pendingCases.pendingCases.failedAcceptingMarkedCorrections");
                    })
                    .finally(function(response) {
                        getCorrections();
                    });
            }
        }

        function denyCorrections() {
            if(vm.correctionsToBeUpdated.length !== 0) {
                PendingCasesService.correctionsDenied(vm.correctionsToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                            AlertService.success("pendingCases.pendingCases.deniedCorrections");
                        } else {
                            AlertService.error("pendingCases.pendingCases.failedDenySomeCorrections");
                        }
                    })
                    .catch(function(response) {
                        AlertService.error("pendingCases.pendingCases.failedDenyCorrections");
                    })
                    .finally(function(response) {
                        getCorrections();
                    });
            }
        }

        function acceptDocuments() {
            if(vm.documentsToBeUpdated.length !== 0) {
                PendingCasesService.waitAccepted(vm.documentsToBeUpdated)
                    .then(function(response) {
                        if (response.data.failed != null && Object.keys(response.data.failed).length !== 0) {
                            var noClientDocs = "";
                            for (var key in response.data.failed) {
                                if (response.data.failed.hasOwnProperty(key)) {
                                    noClientDocs += " " + response.data.failed[key].message.split(" ").splice(-1);
                                } else {
                                    AlertService.error("pendingCases.pendingCases.failedAcceptMarkedInvoices");
                                }
                            }
                            if (noClientDocs !== "") {
                                AlertService.error('pendingCases.pendingCases.missingClients', { docs: noClientDocs } );
                            }
                        } else {
                            AlertService.success("pendingCases.pendingCases.acceptedInvoices");
                        }
                    })
                    .catch(function(response) {
                        AlertService.error("pendingCases.pendingCases.failedAcceptMarkedInvoices");
                    })
                    .finally(function(response) {
                        getClientDocuments();
                    });
            }
        }

        function denyDocuments() {
            if(vm.documentsToBeUpdated.length !== 0) {
                PendingCasesService.waitDenied(vm.documentsToBeUpdated)
                .then(function(response) {
                    if (response.data.failed == null || Object.keys(response.data.failed).length === 0) {
                        AlertService.success("pendingCases.pendingCases.successDenyMarkedInvoices");
                    } else {
                        AlertService.error("pendingCases.pendingCases.failedDenySomeInvoices");
                    }
                })
                .catch(function(response) {
                    AlertService.error("pendingCases.pendingCases.failedDenyMarkedInvoices");
                })
                .finally(function(response) {
                    getClientDocuments();
                });
            }
        }

        function checkAllUsers() {
            vm.usersToBeUpdated = []; //reset selection
            if(vm.checkAllUsersButton) { //select all
                setUserCheckboxes(true); 
                fillSelectedUsersArray();
            } else {
                setUserCheckboxes(false);
            }
            vm.checkAllUsersButton = !vm.checkAllUsersButton;
        }

        function setUserCheckboxes(checked){
            var i;
            for (i = 0; i < vm.users.length; ++i) {
                vm.users[i].selected = checked;
            }
        }
        
        function fillSelectedUsersArray(){
            var i;
            for (i = 0; i < vm.users.length; ++i) {
                vm.usersToBeUpdated.push(i);
            }
        }

        function checkAllCorrections() {
            vm.correctionsToBeUpdated = []; //reset selection
            if(vm.checkAllCorrectionsButton) { 
                selectAllDocs(vm.corrections, vm.correctionsToBeUpdated);
            } else {
                clearAllDocs(vm.corrections);
            }
            vm.checkAllCorrectionsButton = !vm.checkAllCorrectionsButton;
        }

        function checkAllDocuments() {
            vm.documentsToBeUpdated = []; //reset selection
            if(vm.checkAllDocumentsButton) {
                selectAllDocs(vm.documents, vm.documentsToBeUpdated);
            } else {
                clearAllDocs(vm.documents);
            }
            vm.checkAllDocumentsButton = !vm.checkAllDocumentsButton;
        }

        function selectAllDocs(sourceArray, selectedArray){
            angular.forEach(sourceArray, function(item) {
                item.selected = true;
                selectedArray.push(item.transactionId);
            });
        }

        function clearAllDocs(sourceArray){
            angular.forEach(sourceArray, function(item) {
                item.selected = false;
            });
        }

        function getNameBySapNumber(event, index, sapNumber) {
            if (sapNumber != null && sapNumber.length === 7) {
                PendingCasesService.getNameBySapNumber(sapNumber)
                    .then(function(response) {
                        if(response.data != null) {
                            vm.users[index].clientName = response.data.name;
                        }
                    })
                    .catch(function(response) {
                        AlertService.error("pendingCases.pendingCases.clientNotFound", { num: sapNumber } );
                    });
            }
        }

        function getCorrections() {
            PendingCasesService.getCorrections()
                .then(function(response) {
                    vm.corrections = response.data;
                })
                .catch(function(response) {
                    AlertService.error("pendingCases.pendingCases.getCorrectionsFail");
                }).finally(function(response) {
                    vm.correctionsToBeUpdated = []; //reset selection
                    vm.checkAllCorrectionsButton = true;
                });
        }

        function getClientDocuments() {
            PendingCasesService.getClientWaitDocuments()
                .then(function(response) {
                    vm.documents = response.data;
                })
                .catch(function(response) {
                    AlertService.error("pendingCases.pendingCases.getDocumentsFail");
                }).finally(function(response) {
                    vm.documentsToBeUpdated = []; //reset selection
                    vm.checkAllDocumentsButton = true;
                });
        }

        function getUsers() {
            PendingCasesService.getUsers()
                .then(function(response) {
                    vm.users = response.data;
                })
                .catch(function(response) {
                    AlertService.error("pendingCases.pendingCases.getUsersFail");
                }).finally(function(response) {
                    vm.usersToBeUpdated = []; //reset selection
                    vm.checkAllUsersButton = true;
                });
        }

        function downloadPDF(ediDocId) {
            //            PendingCasesService.getDocument(ediDocId)
            //                .then(function(response) {
            //                    var date = new Date();
            //                    FileSaver.saveAs(new Blob([response.data], { type: "application/pdf" }),
            //                        "Document_" + (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()) + ".pdf");
            //                    if (Object.keys(response.data.failed).length != 0) {
            //                        AlertService.error('Nie udało się pobrać dokumentu.');
            //                    }
            //                })
            //                .catch(function(response) {
            //                    AlertService.error('Nie udało się pobrać dokumentu.');
            //                })
            //                .finally(function(response) {
            //                    getCorrections();
            //                });

            var url = DocumentsService.getPdfURL(ediDocId, null, null);
            window.open(url, '_blank');
        }

        function init() {
            getCorrections();
            getClientDocuments();
            getUsers();
        }

        //Init controller
        vm.init();
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PasswordExpireController', PasswordExpireController);

    PasswordExpireController.$inject = ['$scope', 'Principal', '$state', '$stateParams'];

    function PasswordExpireController ($scope, Principal, $state, $stateParams) {
        var vm = this;
        vm.passwordExpireDays = $stateParams.days;
    }
})();

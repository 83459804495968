(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', 'Auth', '$translate', 'AlertService', 'Principal'];

    function LoginController($rootScope, $state, Auth, $translate, AlertService, Principal) {
        var vm = this;

        //Declare functions
        vm.login = login;
        vm.loginreq = loginreq;
        vm.pwdreq = pwdreq;

		// Used to show warnings about maintanance periods
//		AlertService.success(function(){
//		    return $translate.instant()
//		});

        function loginreq() {
            return $translate.instant('login.loginreq');
        }

        function pwdreq() {
            return $translate.instant('login.pwdreq');
        }

        function login(event) {
            event.preventDefault();
            Auth.login({
                username: vm.username + '_DHL',
                password: vm.password
            }).then(function(response) {
                vm.authenticationError = false;
                $rootScope.$broadcast('authenticationSuccess');
                if (response.data.show == true) {
                    $state.go('password-expire', { days: response.data.days });
                } else {
                    logUser();
                }
            }).catch(function(response) {
                vm.authenticationError = true;
                switch (response.status) {
                    case 401:
                        Auth.isBlocked(vm.username)
                            .then(function(response) {
                                if (response.data.parameter1) {
                                    //account blocked due to too many failed logins
                                    AlertService.error('login.10times');
                                } else if (response.data.parameter2) {
                                    //password has expired
                                    AlertService.error('login.expired');
                                } else {
                                    //user does not exist
                                    AlertService.error('login.incorrect');
                                }
                            }).catch(function(response) {
                                AlertService.error('login.incorrect');
                            });
                        break;
                    case 403:
                        AlertService.error('login.auth');
                        break;
                    case 404:
                        AlertService.error('login.notfound');
                        break;
                    case 408:
                        AlertService.error('login.timeout');
                        break;
                    case 500:
                        AlertService.error('login.internal');
                        break;
                    default:
                        AlertService.error('login.failure');
                }
            });
        }

        function logUser() {
            if (angular.isDefined(Auth.getPreviousState())) {
                var previousState = Auth.getPreviousState();
                Auth.resetPreviousState();
                $state.go(previousState.name, previousState.params);
            } else {
                $state.go(Principal.hasInvoiceRead() ? 'documents' : (Principal.hasUserReceivablesAccess() || Principal.hasAdminReceivablesAccess() ? 'receivables' : 'settings'));
            }
        }
    }
})();

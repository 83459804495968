(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('RolesService', RolesService);

    RolesService.$inject = ['API_URL', '$http'];

    function RolesService(API_URL, $http) {
        var service = {
            list: list,
            getRoles: getRoles,
            getPermissions: getPermissions,
            getPermissionsByRoleId: getPermissionsByRoleId,
            createRole: createRole,
            updateRole: updateRole,
            deleteRole: deleteRole
        };

        return service;

        function list() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/ediRoles'
            });
        }

        function getRoles() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/ediRoles?size=2147483647'
            });
        }

        function getPermissions() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/ediPermissions'
            });
        }

        function getPermissionsByRoleId(roleId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/ediRoles/'+roleId+'/permissions'
            });
        }

        function createRole(role) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/ediRoles',
                data: role
            });
        }

        function updateRole(roleId, role) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/ediRoles/' + roleId + '/update',
                data: role
            });
        }

        function deleteRole(roleId) {
            return $http({
                method: 'DELETE',
                url: API_URL + 'api/ediRoles/' + roleId
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('DocumentsService', DocumentsService);

    DocumentsService.$inject = ['API_URL', '$http', '$httpParamSerializer', 'UtilService', 'GenerateURL'];

    function DocumentsService(API_URL, $http, $httpParamSerializer, UtilService, GenerateURL) {
        var service = {
            list: list,
            getUserColumnConf: getUserColumnConf,
            setUserColumnConf: setUserColumnConf,
            getPdf: getPdf,
            getXlsx: getXlsx,
            getPdfs: getPdfs,
            resendEmailForAllDocuments: resendEmailForAllDocuments,
            resendEmailOnNewAdresses: resendEmailOnNewAdresses,
            getPdfURL: getPdfURL,
            getInvoiceXlsxURL: getInvoiceXlsxURL,
            getPdfsURL: getPdfsURL,
            getXlsxURL: getXlsxURL,
            isAvailable: isAvailable
        };

        return service;

        function list(filter) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/getLimited',
                data: UtilService.starsToPercents(filter)
            });
        }

        function getUserColumnConf(UID) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlUsers/' + UID + '/userConf',

            });
        }

        function setUserColumnConf(UID, columnConf) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlUsers/' + UID + '/userConf',
                data: columnConf
            });
        }

        function getPdf(EdiDocId) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/' + EdiDocId + '/pdf',
                responseType: 'arraybuffer'
            });
        }

        function getXlsx(filter) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/getAsExcelByCustomCriteria',
                data: UtilService.starsToPercents(filter), //filter,
                responseType: 'arraybuffer'
            });
        }

        function getPdfs(EdiDocIds, includePdf, includeScan, language) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/getPdfs',
                params: {
                    ids: EdiDocIds,
                    includePdf: includePdf,
                    includeScan: includeScan,
                    language: language
                },
                responseType: 'arraybuffer'
            });
        }

        function getPdfsURL(EdiDocIds, includePdf, includeScan, language) {
            var params = {
                ids: EdiDocIds,
                includePdf: includePdf,
                includeScan: includeScan,
                language: language
            };
            var serializedParams = $httpParamSerializer(params);
            var baseURL = API_URL + 'api/dhlInvoices/downloadPdfs';
            if (serializedParams.length > 0) {
                baseURL += ((baseURL.indexOf('?') === -1) ? '?' : '&') + serializedParams;
            }

            return baseURL;
        }

        function resendEmailForAllDocuments(EdiDocIds) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/resendEmails',
                data: EdiDocIds
            });
        }

        function resendEmailOnNewAdresses(EdiDocIds, Emails) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlInvoices/sendNewEmails',
                data: {
                    docIds: EdiDocIds,
                    emails: Emails
                }
            });
        }


        function getPdfURL(EdiDocId, download, forceFullDocument) {
            var params = { "download": download, "forceFullDocument": forceFullDocument };
            var baseURL = API_URL + 'api/dhlInvoices/' + EdiDocId + '/pdf';

            return GenerateURL.generate(baseURL, params);
        }


        function getInvoiceXlsxURL(EdiDocId) {
            var params = {};
            var baseURL = API_URL + 'api/dhlInvoices/' + EdiDocId + '/xlsx';

            return GenerateURL.generate(baseURL, params);
        }

        function getXlsxURL(filter) {
            var params = UtilService.starsToPercents(filter);
            var baseURL = API_URL + 'api/dhlInvoices/getAsExcelByCustomCriteria';
            return GenerateURL.generate(baseURL, params);
        }

        function isAvailable(uuid, type) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/isAvailable',
                params: {
                    documentId: uuid,
                    documentType: type
                }
            });
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('iframeSetDimensionsOnload', [function() {

            return {
                restrict: 'A',
                link: function(scope, element, attrs){
                    element.on('load', function(){
                        /* Set the dimensions here,
                           I think that you were trying to do something like this: */
                           var iFrameHeight = element[0].contentWindow.document.body.scrollHeight + 'px';
                           var iFrameWidth = '100%';
                           element.css('width', iFrameWidth);
                           element.css('height', iFrameHeight);
                            var Window = $(window);
                            var Document = $(document);
                            element.css('height', Document.height());
                            element.css('height', element.height() - (Document.height() - Window.height()));

//                           $(window).resize(function(){
//                                var Window = $(window),
//                                Document = $(document),
//                                PDFWrapper = $('#PDFWrapper');
//                           });

                    })
                }
            }
        }]);
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['$scope', '$state', '$stateParams', 'UsersService', 'ClientsService', 'UtilService', 'MailTemplatesService', 'filterFilter', 'Principal', 'AlertService', '$translate'];

    function ClientController($scope, $state, $stateParams, UsersService, ClientsService, UtilService, MailTemplatesService, filterFilter, Principal, AlertService, $translate) {
        var vm = this;

        vm.$onInit = function() {
            vm.filter.read();
            if (UtilService.isFilled(vm.filter.data))
                vm.client.get();
            else
                $state.go('clients');
        };

        vm.getAllEmailTemplates = getAllEmailTemplates;
        vm.getUserTemplatesId = getUserTemplatesId;
        vm.sendNewUserTemplates = sendNewUserTemplates;
        vm.getCommonElementFromLists = getCommonElementFromLists;

        vm.filter = {
            data: {
                clientNumber: ""
            },
            clear: function() {
                vm.filter.data.clientNumber = "";
            },
            save: function() {
                $state.transitionTo($state.current, vm.filter.data, {
                    location: true,
                    inherit: true,
                    notify: false
                });
            },
            read: function() {
                angular.copy($stateParams, vm.filter.data);
            }
        };

        vm.ActivityTypes = [{
            name: 'ACCOUNT_OPENED',
            text: function() {
                return $translate.instant("clients.client.accountOpen");
            }
        }, {
            name: 'EMAIL_ADDED',
            text: function() {
                return $translate.instant("clients.client.emailAdded");
            }
        }, {
            name: 'REGISTRATION_FAIL',
            text: function() {
                return $translate.instant("clients.client.registrationFail");
            }
        }, {
            name: 'PASSWORD_CHANGE',
            text: function() {
                return $translate.instant("clients.client.passwordChange");
            }
        }, {
            name: 'PAPER_INVOICE',
            text: function() {
                return $translate.instant("clients.client.paperInvoice");
            }
        }, {
            name: 'INVOICE_WITH_LINK',
            text: function() {
                return $translate.instant("clients.client.invoiceWithLink");
            }
        }, {
            name: 'INVOICE_WITH_PDF',
            text: function() {
                return $translate.instant("clients.client.invoiceWithPdf");
            }
        }, {
            name: 'INVOICE_WITH_LINK_AND_XLSX',
            text: function() {
                return $translate.instant("clients.client.invoiceWithLinkXlsx");
            }
        }, {
            name: 'INVOICE_WITH_PDF_AND_XLSX',
            text: function() {
                return $translate.instant("clients.client.invoiceWithPdfXlsx");
            }
        }, {
            name: 'CORRECTION_INVOICE',
            text: function() {
                return $translate.instant("clients.client.correctionsInvoice");
            }
        }, {
            name: 'INVOICE_TOO_LARGE',
            text: function() {
                return $translate.instant("clients.client.invoiceTooLarge");
            }
        }];

        vm.clientTemplates = {
            templates: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titles: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            templatesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            },
            titlesEN: {
                ACCOUNT_OPENED: {
                    name: null,
                    template_id: null
                },
                EMAIL_ADDED: {
                    name: null,
                    template_id: null
                },
                REGISTRATION_FAIL: {
                    name: null,
                    template_id: null
                },
                PASSWORD_CHANGE: {
                    name: null,
                    template_id: null
                },
                PAPER_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_LINK_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                INVOICE_WITH_PDF_AND_XLSX: {
                    name: null,
                    template_id: null
                },
                CORRECTION_INVOICE: {
                    name: null,
                    template_id: null
                },
                INVOICE_TOO_LARGE: {
                    name: null,
                    template_id: null
                }
            }
        };

        vm.dhlEmailTempaltes = null;
        vm.dhlEmailTempaltesIds = null;
        vm.templatesToSend = [];

        vm.data = {
            client: null,
            user: null
        };

        //Managing client
        vm.client = {
            get: function() {
                ClientsService.findByClientNumber(vm.filter.data.clientNumber)
                    //If successful
                    .then(vm.client.then)
                    //If there were errors
                    .catch(function(response) {})
                    .finally(function(response) {});
            },
            then: function(response) {
                //Set data to controller data
                vm.data.client = response.data;

                //Get his users
                vm.client.getUsers();

                //Write state to URL
                vm.filter.save();

                //Get his templates
                vm.getAllEmailTemplates();
            },
            getUsers: function() {
                vm.data.client.users = [];
                vm.data.client.receivablesUsers = [];
                UtilService.get /*his users*/ (vm.data.client, 'dhlUsers', null, null)
                    .then(function(response) {
                        //Set users to client
                        if(response.data._embedded != null && response.data._embedded.dhlUsers != null) {
                            angular.forEach(response.data._embedded.dhlUsers, function(item) {
                                item.email = item.email.toLowerCase();  //all emails uniformly in lowercase
                                if (item.receivablesUser)
                                    vm.data.client.receivablesUsers.push(item);
                                if (item.documentsUser)
                                    vm.data.client.users.push(item);
                            });
                        }
                    });
            },
            cancel: function() {
                vm.client.get();
            },
            update: function() {
                var clientDataCopy = formatClientData(vm.data.client); //backend sends and expects both rec & doc users in one list
                ClientsService.update(clientDataCopy)
                    .then(function(response) {
                        AlertService.success("clients.client.changesSaved");
                    }).catch(function(response) {
                        AlertService.error("clients.client.saveFailed");
                    });
            }
        };

        function formatClientData(clientDataSource) {
            var clientData = JSON.parse(JSON.stringify(clientDataSource)); //create deep copy

            var allUsers = [];
            angular.forEach(clientData.receivablesUsers, function(user) {
                allUsers.push({ email: user.email, receivablesUser: true, documentsUser: false });
            });
            angular.forEach(clientData.users, function(docUser) {
                var idx = allUsers.map(
                        function(user) {
                            return user.email;
                        })
                    .indexOf(docUser.email);
                if(idx === -1)
                    allUsers.push({ email: docUser.email, receivablesUser: false, documentsUser: true });
                else
                    allUsers[idx].documentsUser = true;
            });

            delete clientData.receivablesUsers;
            clientData.users = allUsers;
            return clientData;
        }

        vm.user = {
            getByEmail: function(email) {
                if(Principal.hasUserRead()){
                    return UsersService.getEmailsLike({ email: email.toLowerCase() })
                        .then(function(response) {
                            if (!response.data._embedded) {
                                return "";
                            }
                            return response.data._embedded.dhlUsers.map(function(user) {
                                return user.email.toLowerCase();
                            });
                        });
                }
            },
            add: function() {
                if (angular.isDefined(vm.data.userEmail) && vm.data.userEmail != "" && vm.data.userEmail != null) { //if user email input provided
                    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(vm.data.userEmail)) { //if valid email format
                        if (vm.data.client.users.map(
                                function(user) {
                                    return user.email;
                                }).indexOf(vm.data.userEmail.toLowerCase()) === -1) { //if user is not already assigned
                            vm.data.client.users.push({ email: vm.data.userEmail.toLowerCase() }); //add user
                            vm.data.userEmail = "";
                        } else {
                            AlertService.error("clients.client.duplicateEmail");
                        }
                    } else {
                        AlertService.error("clients.client.invalidEmail");
                    }
                }
            },
            
            delete: function() {
                var index = vm.data.client.users.map(function(user) {
                    return user.email;
                }).indexOf(vm.data.userEmail.toLowerCase());

                if (index !== -1) {
                    vm.data.client.users.splice(index, 1);
                    vm.data.userEmail = "";
                }
            }
        };

        //Define a watch for user list select
        $scope.$watch('vm.selection', function(newValue) {
            if (newValue != null && newValue.length !== 0) {
                vm.data.userEmail = newValue[0].email.toLowerCase();
            }
        });

        $scope.$watch('vm.data.client.nip', function() {
            if (vm.data.client && UtilService.isFilled(vm.data.client.nip))
                vm.data.client.nip = vm.data.client.nip.replace(/\D/g, '');
        });

        //Navigating
        vm.nav = {
            clients: function() {
                $state.go('clients');
            },
            user: function(user) {
                $state.go('user', user);
            }
        };

        function getAllEmailTemplates() {
            if (Principal.hasTemplateAccess()) {
                MailTemplatesService.getAllTemplates().then(function(response) {
                    if (response.data._embedded != null) {
                        vm.dhlEmailTempaltes = response.data._embedded.dhlTemplates;
                        vm.getUserTemplatesId();
                    } else {
                        vm.clientErrorText = $translate.instant('clients.client.templatesDownloadFailed');
                        vm.clientSuccessText = null;
                    }
                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesDownloadFailed");
                    vm.clientSuccessText = null;
                });
            }
        }

        function getUserTemplatesId() {
            if (Principal.hasTemplateAccess()) {
                ClientsService.getClientTemplates(vm.data.client.ediClientId).then(function(response) {
                    vm.dhlEmailTempaltesIds = response.data;

                    var id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PAPER_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titles.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PAPER_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'pl' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templates.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'PAPER_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_TITLE', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];




                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'ACCOUNT_OPENED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.ACCOUNT_OPENED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'EMAIL_ADDED', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.EMAIL_ADDED = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'REGISTRATION_FAIL', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.REGISTRATION_FAIL = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PASSWORD_CHANGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.PASSWORD_CHANGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'PAPER_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.PAPER_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_LINK_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_WITH_PDF_AND_XLSX', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'CORRECTION_INVOICE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.CORRECTION_INVOICE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                    id = vm.getCommonElementFromLists(filterFilter(vm.dhlEmailTempaltes, { type: 'EMAIL_CONTENT', actionType: 'INVOICE_TOO_LARGE', language: 'en' }, true), vm.dhlEmailTempaltesIds);
                    vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE = (filterFilter(vm.dhlEmailTempaltes, { ediTemplateId: id }))[0];

                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesDownloadFailed");
                    vm.clientSuccessText = null;
                });
            }
        }

        function getCommonElementFromLists(list1, list2) {
            var id;
            angular.forEach(list1, function(value, key) {
                if (list2.indexOf(value.ediTemplateId) > -1) {
                    id = value.ediTemplateId;
                }
            });
            return id;
        }

        //client + listofid
        function sendNewUserTemplates() {
            if (Principal.hasTemplateAccess()) {
                vm.templatesToSend = [];

                vm.templatesToSend.push(vm.clientTemplates.titles.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.PAPER_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titles.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templates.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.PAPER_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templates.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.titlesEN.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.PAPER_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.titlesEN.INVOICE_TOO_LARGE.ediTemplateId);

                vm.templatesToSend.push(vm.clientTemplates.templatesEN.ACCOUNT_OPENED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.EMAIL_ADDED.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.REGISTRATION_FAIL.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.PASSWORD_CHANGE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.PAPER_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_LINK.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_PDF.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_LINK_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_WITH_PDF_AND_XLSX.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.CORRECTION_INVOICE.ediTemplateId);
                vm.templatesToSend.push(vm.clientTemplates.templatesEN.INVOICE_TOO_LARGE.ediTemplateId);


                ClientsService.postClientTemplates(vm.data.client.ediClientId, vm.templatesToSend).then(function(response) {
                    vm.clientErrorText = null;
                    vm.clientSuccessText = $translate.instant("clients.client.templatesEditSuccess");
                }).catch(function(response) {
                    vm.clientErrorText = $translate.instant("clients.client.templatesEditFail");
                    vm.clientSuccessText = null;
                });
            }
        }
    }
})();

(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .factory('ClientsService', ClientsService);

    ClientsService.$inject = ['API_URL', '$http', 'UtilService'];

    function ClientsService(API_URL, $http, UtilService) {
        var service = {
            create: create,
            findByClientNumber: findByClientNumber,
            getClientTemplates: getClientTemplates,
            list: list,
            listByUserEmail: listByUserEmail,
            listWithParams: listWithParams,
            manage: manage,
            parseManagementFile: parseManagementFile,
            postClientTemplates: postClientTemplates,
            update: update,
            getVersion: getVersion
        };

        return service;

        function list(filter) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients',
                params: filter
            });
        }

        function getVersion() {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlInvoices/version'
            });
        }

        function listWithParams(filter) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/findDhlClients',
                params: UtilService.starsToPercents(UtilService.nullsToEmptyStrings(filter))
            });
        }

        function create(client) {
            return $http({
                method: 'PUT',
                url: API_URL + 'api/dhlClients/create',
                data: UtilService.emptyStringsToNulls(client)
            });
        }

        function update(client) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlClients/update',
                data: client
            });
        }

        function listByUserEmail(filter) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/search/findAllWithClientsByEmail',
                params: filter
            });
        }

        function findByClientNumber(clientNumber) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/search/findByClientNumber',
                params: { 'clientNumber': clientNumber }
            });
        }

        function parseManagementFile(file) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlClients/manage/parse',
                data: file
            });
        }

        function manage(actions) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlClients/manage',
                data: actions
            });
        }

        function getClientTemplates(client) {
            return $http({
                method: 'GET',
                url: API_URL + 'api/dhlClients/' + client + '/getTemplatesId'
            });
        }

        function postClientTemplates(client, templates) {
            return $http({
                method: 'POST',
                url: API_URL + 'api/dhlClients/' + client + '/postTemplates',
                data: templates
            });
        }
    }
})();

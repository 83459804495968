(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .directive('invoice', ['UtilService', 'UsersService', '$q', '$translate', '$translatePartialLoader', function(UtilService, UsersService, $q, $translate, $translatePartialLoader) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' name='dhlInvoice' class='form-control register-input normal-form' autocomplete='off' id='dhlInvoice' placeholder='' required='required' ng-model='vm.data.invoiceNr' ng-maxlength='256' ng-class='registerInvoice' uib-tooltip={{invoiceTooltip()}} tooltip-placement='top' />",
                link: function(scope, element, attributes, control) {
                    $translatePartialLoader.addPart('register');
                    $translate.refresh();
                    // Only numbers
                    control.$parsers.push(function(val) {
                        if (angular.isUndefined(val)) {
                            var val = '';
                        }
                        var clean = val.replace(/[^0-9]+/g, '');
                        if (val !== clean) {
                            control.$setViewValue(clean);
                            control.$render();
                        }
                        return clean;
                    });

                    $(element).bind('keypress', function(event) {
                        if (event.keyCode === 32) {
                            event.preventDefault();
                        }
                    });

                    // Required
                    control.$validators.required = jQuery.proxy(function(value) {
                        return (!control.$isEmpty(value));
                    }, element);

                    scope.invoiceTooltip = function() {
                        var tooltipInvoice = '';
                        if (control.$error.required)
                            tooltipInvoice = $translate.instant("directives.requiredInvoice");
                        return tooltipInvoice;
                    }

                    $(element).on('keyup change ', function(event) {
                        if ($("#dhlInvoice").hasClass("ng-invalid"))
                            $("#dhlInvoicedhlLabel").addClass("red")
                        else
                            $("#dhlInvoicedhlLabel").removeClass("red")
                    });
                }
            }
        }]);
})();

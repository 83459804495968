(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('PdfViewController', PdfViewController);

    PdfViewController.$inject = ['$scope', '$state', '$stateParams', 'DocumentsService', 'Principal', "PdfViewService", 'tmhDynamicLocale', '$translate', '$http'];

    function PdfViewController($scope, $state, $stateParams, DocumentsService, Principal, PdfViewService, tmhDynamicLocale, $translate, $http) {
        var vm = this;

        vm.userOnePdfPage = null;
        vm.documentNumber = null;
        vm.tekst = $translate.instant("pdfView.downloadWhole");
        vm.translate = $translate.instant;
        vm.init = init;
        vm.getDownloadFullPdfURL = getDownloadFullPdfURL;
        vm.getDownloadXlsxURL = getDownloadXlsxURL;
        vm.downloadError = null;
//        vm.downloadXlsx = downloadXlsx;
        vm.pdfUrl = DocumentsService.getPdfURL($stateParams.uuid, false, false);
        vm.pdfViewer = './external/pdf-view/web/viewer.html?file=' + vm.pdfUrl + '#locale=' + tmhDynamicLocale.get();
        vm.isExcelAvailable = false;

        function init() {
            if ($stateParams.uuid == null) {
                $state.go('404');
            }


            if (Principal.isAuthenticated()) {
                PdfViewService.getInvoiceStylesheet($stateParams.uuid).then(function(response) {
                    vm.userOnePdfPage = response.data.stylesheet;
                    vm.documentNumber = response.data.number;
                }).catch(function(response) {
                    $state.go('404');
                });
            }

            //Check whether Excel file is ready for download
            DocumentsService.isAvailable($stateParams.uuid, "XLSX")
            .then(function(response){
                vm.isExcelAvailable = response.data;
            });
        }

        function getDownloadFullPdfURL(forceFullPDF) {
            return DocumentsService.getPdfURL($stateParams.uuid, true, forceFullPDF);
        }

        function getDownloadXlsxURL() {
            return DocumentsService.getInvoiceXlsxURL($stateParams.uuid);
        }

        //Init controller
        vm.init();

        /*
            The piece of code below shows AngularJS Loading Spinner until PDF document is not loaded. It's a workaround, beacause IFrame does not allow to capture its internal events outside.
            In order to show the spinner, we have to have at least one pending HTTP request - that's why empty object is inserted into the $http.pendingRequests array.
            When PDF document is loaded - the faked request is removed.
        */
        var loadingRequestPlaceholder = { notice: "Do not remove this object. It resides here to show AngularJS Loading Spinner. It will be removed automatically. Author: DD" };
        $http.pendingRequests.push(loadingRequestPlaceholder);

        var PDFWrapper = document.getElementById('PDFWrapper');
        PDFWrapper.onload = function() {
            $(this.contentDocument.getElementById('viewer')).one('DOMSubtreeModified', removeRequest);
        };
        $scope.$on("$destroy", function() {
            removeRequest();
        });

        function removeRequest(event) {
            var index = $http.pendingRequests.indexOf(loadingRequestPlaceholder);
            if (index !== -1) $http.pendingRequests.splice(index, 1);
            if (event)
                $scope.$apply();
        }
    }
})();

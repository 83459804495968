(function() {
    'use strict';

    angular
        .module('edistradadhluiApp')
        .controller('ContactController', ContactController);

    ContactController.$inject = ['$translate'];

    function ContactController($translate) {
        var vm = this;

        vm.filter = null;
        vm.data = null;
        vm.language = function() {
            return $translate.use();
        };
    }
})();
